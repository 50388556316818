import { useEffect } from "react";
import "../App.css";
export default function Canvas({ divId }) {
  var stars = [];
  var canvas;
  var ctx;
  var FPS;
  var x;
  var mouse;
  var i;

  function doStarsStuff() {
    canvas = document.getElementById(divId);

    ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    stars = []; // Array that contains the stars
    FPS = 60; // Frames per second
    if (divId === "footerStars") {
      x = 30;
    } else {
      x = window.innerWidth >= 900 ? 65 : 30; // Number of stars
    }

    mouse = {
      x: 0,
      y: 0,
    }; // mouse location

    for (i = 0; i < x; i++) {
      stars.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 1 + 3,
        vx: Math.floor(Math.random() * 50) - 25,
        vy: Math.floor(Math.random() * 50) - 25,
      });
    }

    tick();
  }

  function draw() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.globalCompositeOperation = "lighter";

    for (i = 0; i < stars.length; i++) {
      var s = stars[i];

      ctx.fillStyle = "#576FDB";
      ctx.beginPath();
      ctx.arc(s.x, s.y, s.radius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.fillStyle = "black";
      ctx.stroke();
    }

    ctx.beginPath();
    for (i = 0; i < stars.length; i++) {
      var starI = stars[i];
      ctx.moveTo(starI.x, starI.y);
      if (distance(mouse, starI) < 150) ctx.lineTo(mouse.x, mouse.y);
      for (var j = 0, x = stars.length; j < x; j++) {
        var starII = stars[j];
        if (distance(starI, starII) < 150) {
          //ctx.globalAlpha = (1 / 150 * distance(starI, starII).toFixed(1));
          ctx.lineTo(starII.x, starII.y);
        }
      }
    }
    ctx.lineWidth = 0.05;
    ctx.strokeStyle = "#576FDB";
    ctx.stroke();
  }

  function distance(point1, point2) {
    var xs = 0;
    var ys = 0;

    xs = point2.x - point1.x;
    xs = xs * xs;

    ys = point2.y - point1.y;
    ys = ys * ys;

    return Math.sqrt(xs + ys);
  }

  function update() {
    for (var i = 0, x = stars.length; i < x; i++) {
      var s = stars[i];

      s.x += s.vx / FPS;
      s.y += s.vy / FPS;

      if (s.x < 0 || s.x > canvas.width) s.vx = -s.vx;
      if (s.y < 0 || s.y > canvas.height) s.vy = -s.vy;
    }
  }

  function tick() {
    draw();
    update();
    requestAnimationFrame(tick);
  }

  useEffect(() => {
    doStarsStuff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <canvas
        id={divId}
        className="dotCanvas"
        width="200"
        height="100"
      ></canvas>
    </>
  );
}
