import { useState, useEffect, useRef, useMemo } from "react";
import "../App.css";
import { tokenBox } from "../utils/constant";
import chartImg from "../assets/token-dist.png";
export default function TokenDistribtion() {
  const sectionId = "tokenDistribution";
  const sectionRef = useRef(null);
  const isInViewport1 = useIsInViewport(sectionRef);
  if (isInViewport1) {
    document.getElementById(sectionId).className =
      "section animate__animated animate__fadeInUp";
  }
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );
    useEffect(() => {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
    return isIntersecting;
  }
  return (
    <>
      <div id={sectionId} className="section" ref={sectionRef}>
        <div className="row row90">
          <div className="col-sm-12 tokenDistHead">
            <div className="whiteHeading">Initial Token Distribution</div>
          </div>
          <div className="d-flex justify-content-between w-100 tokenBody">
            <div className="grid-boxs">
              {tokenBox.map(({ id, color, txt, percent }) => {
                return (
                  <div className="d-flex tokenContent" key={id}>
                    <div
                      style={{ backgroundColor: color }}
                      className="round-gradient"
                    ></div>
                    <div className="d-flex flex-column w-100">
                      <span className="percent">{percent}</span>
                      <span className="body-txt">{txt}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="image-chart">
              <img src={chartImg} alt="Emorya" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
