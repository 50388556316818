import { useState, useEffect, useRef, useMemo } from "react";
import "../App.css";
export default function Tokens() {
  const sectionId = "tokenSection";
  const sectionRef = useRef(null);
  const isInViewport1 = useIsInViewport(sectionRef);
  if (isInViewport1) {
    document.getElementById(sectionId).className =
      "section preSale animate__animated animate__fadeInUp";
  }
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );
    useEffect(() => {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
    return isIntersecting;
  }

  return (
    <>
      <div id={sectionId} className="section preSale" ref={sectionRef}>
        <div className="row row90" id="token-sale">
          <div className="col-sm-3"></div>
          <div className="col-sm-6 sports">
            <div className="blueSubHeading">TOKENS</div>
            <div className="whiteHeading">Pre-Sale & Values</div>
            <div className="text">
              Emorya $EMR token was projected to be released on the basis of <span className='green-color'>MultiversX Blockchain</span>. Therefore, this token should provide compatibility with third-party services, wallets, exchanges, etc, as well as easy-to-use integration features.
            </div>
          </div>
          <div className="col-sm-3"></div>

          <div className="col-sm-12 tokenBox" style={{}}>
            <div className="row">
              <div
                className="col-sm-4 showOnMobile"
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  alt="Emorya"
                  src={require("../assets/pre-sale.png")}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
              <div className="col-sm-4">
                <div className="divPad">
                  <i className="fas fa-circle gradColor"></i>
                  &nbsp;&nbsp;Start
                  <div className="tba">Sep 01, 2023 (12:00 AM EEST)</div>
                </div>

                <div
                  className="divPad"
                  style={{
                    paddingLeft: "0%",
                  }}  
                >
                  <i className="fas fa-circle gradColor"></i>
                  &nbsp;&nbsp;Acceptable Currencies
                  <div className="tba">$USDC</div>
                </div>

                <div className="divPad">
                  <i className="fas fa-circle gradColor"></i>
                  &nbsp;&nbsp;End
                  <div className="tba">Nov 07, 2023 (12:00 AM EEST)</div>
                </div>
              </div>
              <div
                className="col-sm-4 showOnDesktop"
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  alt="Emorya"
                  src={require("../assets/pre-sale.png")}
                  style={{
                    width: "100%",
                  }}
                />
              </div>

              <div className="col-sm-4 divPadRight">
                <div className="divPad">
                  Number of tokens for sale &nbsp;&nbsp;
                  <i className="fas fa-circle gradColor"></i>
                  <div className="tba">100,000,000 $EMR (10%)</div>
                </div>

                <div
                  className="divPad"
                  style={{
                    paddingRight: "0%",
                  }}
                >
                  Tokens exchange rate &nbsp;&nbsp;
                  <i className="fas fa-circle gradColor"></i>
                  <div className="tba">1 $EMR = 0.02 $USD</div>
                </div>

                <div className="divPad">
                  Minimal Transaction Amount &nbsp;&nbsp;
                  <i className="fas fa-circle gradColor"></i>
                  <div className="tba">1 USDC</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
