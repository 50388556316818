import "../App.css";
import BlogSection from "./BlogSection";
import BurnToMillion from "./BurnToMillion";
import Contact from "./Contact";
import Dapps from "./Dapps";
import FeeBreakDown from "./FeeBreakDown";
import FeeBreakDownMobile from "./FeeBreakDownMobile";
import Footer from "./Footer";
import Header from "./Header";
import IntroSection from "./IntroSection";
import ReachOut from "./ReachOut";
import RoadMap from "./RoadMap";
import Sports from "./Sports";
import Team from "./Team";
import Ambassadors from "./Ambassadors";
import Partners from "./Partners";
import TokenDistribtion from "./TokenDistribution";
import Tokens from "./Tokens";
import WhatIsEmorya from "./WhatIsEmorya";

import Calculator from "./Calculator";
import Companyes from "./Companyes";
import AppVideo from "./AppVideo";
export default function Home() {
  const blogEnabled = process.env.REACT_APP_BLOG_ENABLED;

  return (
    <>
      <Header homepage={true}></Header>
      <IntroSection></IntroSection>
      <AppVideo></AppVideo>
      
      <div className="burnRow">
        
        <WhatIsEmorya></WhatIsEmorya>
        <BurnToMillion></BurnToMillion>
        <Sports></Sports>
      </div>
      <Calculator></Calculator>
      <Tokens></Tokens>
      <TokenDistribtion></TokenDistribtion>
      <FeeBreakDown></FeeBreakDown>
      <FeeBreakDownMobile></FeeBreakDownMobile>
      <RoadMap></RoadMap>
      <Dapps></Dapps>
      <Team></Team>
      <Ambassadors></Ambassadors>
      <Partners></Partners>
      <Contact heading={true}></Contact>
      <ReachOut></ReachOut>
      {blogEnabled === "true" ? (
        <>
          <BlogSection></BlogSection>
        </>
      ) : (
        <></>
      )}
      <Companyes></Companyes>
      <Footer></Footer>
    </>
  );
}
