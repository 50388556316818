import { useState, useEffect } from "react";
import "../App.css";
import Canvas from "./Canvas";
export default function EarlyAccessHead() {
  const [days, setDays] = useState("");
  const [hours, setHours] = useState("");
  const [mins, setMins] = useState("");
  const [seconds, setSeconds] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [twitterAccount, setTwitterAccount] = useState("");
  const [thankyou, setThankyou] = useState(false);
  const [submitText, setSubmitText] = useState("Book Now");
  const [thankyouText, setThankyouText] = useState(
    "Thankyou " +
      name +
      " for connecting with us. We have received your query and our team will connect with you as soon as possible."
  );

  const contactEmail = process.env.REACT_APP_EARLY_ACCESS_EMAIL;
  const postUrl = process.env.REACT_APP_EARLY_ACCESS_POST_URL;
  const appTimer = process.env.REACT_APP_LAUNCH_DATE;
  const saveForm = () => {
    if (name === "" || email === "" || twitterAccount === "") {
      setThankyouText("Please enter all the fields to book.");
      setThankyou(true);
    } else {
      var formdata = new FormData();
      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("twitter", twitterAccount);
      formdata.append("contactemail", contactEmail);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      setSubmitText("Please wait..");
      fetch("https://parxfit.com/emorya/save-booking.php", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          if (result.status === 1) {
            setName("");
            setEmail("");
            setTwitterAccount("");
            setThankyouText(
              "Thankyou " +
                name +
                " for connecting with us. We have received your booking. You will be redirected in 5 seconds."
            );
            setThankyou(true);
            setSubmitText("Redirecting..");
            setTimeout(function () {
              window.open(postUrl, "_blank");
            }, 5000);
          }
        })
        .catch((error) => {
          setThankyouText(
            "Unable to connect to our query server. Please try again later"
          );
          setThankyou(true);
          setSubmitText("Retry");
        });
    }
  };

  const timer = (endtime) => {
    var timeTotal = Date.parse(endtime) - Date.parse(new Date()),
      timeSeconds = Math.floor((timeTotal / 1000) % 60),
      timeMinutes = Math.floor((timeTotal / 1000 / 60) % 60),
      timeHours = Math.floor((timeTotal / (1000 * 60 * 60)) % 24),
      timeDays = Math.floor(timeTotal / (1000 * 60 * 60 * 24));

    if (timeSeconds < 0) timeSeconds = 0;
    if (timeMinutes < 0) timeMinutes = 0;
    if (timeHours < 0) timeHours = 0;
    if (timeDays < 0) timeDays = 0;

    setDays(timeDays);
    setHours(timeHours);
    setMins(timeMinutes);
    setSeconds(timeSeconds);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      timer(appTimer);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <>
      <Canvas divId="introStars"></Canvas>

      <div className="headRectangle animate__animated animate__fadeInUp">
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 earlyHeadBox"
            style={{
              textAlign: "center",
              paddingTop: 50,
            }}
          >
            <div className="earlyHead">
              <img
                alt="Emorya"
                src={require("../assets/logo.png")}
                style={{
                  width: "300px",
                }}
              />
              <br />
              <div
                className="btn-green-wrapper"
                style={{
                  marginTop: 10,
                  width: "100%",
                }}
              >
                <div className="box-under-green">
                  <div className="earlyHeading">
                    Emorya Finance App - Early Access Whitelist
                  </div>

                  <div className="timerContainer row">
                    <div className="col-sm-3 col-3">
                      <div className="number">{days}</div>
                      <div className="label">days</div>
                    </div>
                    <div className="col-sm-3 col-3">
                      <div className="number">{hours}</div>
                      <div className="label">hours</div>
                    </div>
                    <div className="col-sm-3 col-3">
                      <div className="number">{mins}</div>
                      <div className="label">minutes</div>
                    </div>
                    <div className="col-sm-3 col-3">
                      <div className="number">{seconds}</div>
                      <div className="label">seconds</div>
                    </div>

                    <div
                      className="col-sm-12"
                      style={{
                        borderBottom: 1,
                        borderBottomColor: "#fff",
                        borderBottomStyle: "solid",
                        padding: 15,
                        paddingTop: 20,
                      }}
                    >
                      Welcome to Emorya Finance APP Early Access Whitelist!
                    </div>

                    <div className="col-sm-12 earlyAccess">
                      {thankyou ? (
                        <>
                          <div
                            className="whiteHeading"
                            style={{
                              fontSize: 15,
                              fontWeight: "bold",
                              color:
                                thankyouText.indexOf("Thankyou") !== -1
                                  ? "#fff"
                                  : "#FEAC84",
                            }}
                          >
                            {thankyouText}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="formElement">
                        <div
                          className="btn-green-wrapper"
                          style={{
                            display: "block",
                            width: "100%",
                          }}
                        >
                          <input
                            className="input-green"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="formElement">
                        <div
                          className="btn-green-wrapper"
                          style={{
                            display: "block",
                            width: "100%",
                          }}
                        >
                          <input
                            className="input-green"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="formElement">
                        <div
                          className="btn-green-wrapper"
                          style={{
                            display: "block",
                            width: "100%",
                          }}
                        >
                          <input
                            className="input-green"
                            placeholder="Twitter Account"
                            value={twitterAccount}
                            onChange={(e) => setTwitterAccount(e.target.value)}
                          />
                        </div>
                      </div>

                      <div
                        className="btn-green-wrapper"
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <button
                          className="btn-green"
                          onClick={() => {
                            saveForm();
                          }}
                        >
                          {submitText}
                        </button>
                      </div>
                      <div
                        style={{
                          padding: 15,
                        }}
                      >
                        Fill in all the mandatory Forms above and follow all of
                        the Instructions from our Twitter Post.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
