import "../App.css";

import Footer from "./Footer";
import Header from "./Header";
import Contact from "./Contact";
import ReachOut from "./ReachOut";

export default function ContactForm() {
  
  
  return (
    <>
      <Header homepage={false}></Header>
      <div
        className="contactForm animate__animated animate__fadeInUp"
        style={{
          marginTop: 100,
        }}
      >
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-7"
            style={{
              paddingTop: 50,
            }}
          >
            <div className="green-heading">
              <strong>
                Talk to Us
              </strong>
            </div>
            <div className="text">
              <span
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                You can reach out to us via filling th form below, and our team will connect with you as soon as possible!
              </span>
              <br />
              <br />
            </div>
            <div
              className="btn-green-wrapper"
              style={{
                marginTop: 10,
              }}
            ></div>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-5"
            style={{
              textAlign: "center",
              paddingTop: 50,
            }}
          >
            <img
              src={require("../assets/communicate.png")}
              style={{
                width: "200px",
              }}
              alt="Emorya"
            />
          </div>
          
          
        </div>
        
      </div>
      <Contact heading={false}></Contact>
      <ReachOut></ReachOut>
     
      <Footer></Footer>
    </>
  );
}
