import holderRounds from "../assets/rounds-holder.svg";
import coinRounds from "../assets/rounds-coin.svg";
import logoHolder from "../assets/holder-logo.svg";
import logoCoin from "../assets/coin-logo.svg";

const company = [
  {
    id: 0,
    bg: holderRounds,
    logo: logoHolder,
    linkTxt: "Visit Holder.io",
    href: "https://holder.io/ru/coins/emr/",
  },
  {
    id: 1,
    bg: coinRounds,
    logo: logoCoin,
    linkTxt: "Visit CoinMarketCap.com",
    href: "https://coinmarketcap.com/currencies/emorya-finance/",
  },
];
export default function Companyes() {
  return (
    <div className="section companySection">
      <div className="companyes row row90 justify-content-between">
        {company.map(({ id, bg, logo, linkTxt, href }) => {
          return (
            <div className="companyBox position-relative" key={id}>
              <img
                src={bg}
                alt="holder vector"
                className={`position-absolute rounds-company-${id}`}
              />
              <div className="p-4 z-1 position-relative companycontent d-flex flex-column align-items-center">
                <img src={logo} alt="holder logo" />
                <span className="text-center">
                  Ready to enhance your digital security and streamline your
                  sensitive information management? Dive into the secure vault
                  of Holder.io for a seamless and reliable solution.
                </span>
                <div className="btn-green-wrapper btn-company">
                  <button className="btn-green">
                    <a href={href} target="_blank" rel="noreferrer">
                      {linkTxt}
                    </a>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
