import { useRef } from "react";
import "../App.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { TwitterTweetEmbed } from "react-twitter-embed";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function TweetBox() {
  const blogSwiperRef = useRef(null);

  const tweets = [
    {
      id: "1674469486936698887",
    },
    {
      id: "1674099769415815168",
    },
    {
      id: "1673745957299666961",
    },
    {
      id: "1673374535813414913",
    },
  ];

  return (
    <>
      <div className="section blogSection">
        <div className="row row90 teamContainer">
          <div
            className="col-sm-12 showOnDesktop"
            style={{
              textAlign: "center",
              paddingTop: 50,
            }}
          >
            {tweets.map((blog) => {
              return (
                <div className="TweetBox" key={blog.id}>
                  <TwitterTweetEmbed
                    key={blog.id}
                    onLoad={function noRefCheck() {}}
                    tweetId={blog.id}
                  />
                </div>
              );
            })}
          </div>

          <div
            className="col-sm-12 mobileTeam"
            style={{
              textAlign: "center",
              paddingTop: 50,
            }}
          >
            <Swiper
              ref={blogSwiperRef}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              scrollbar={{ draggable: true }}
            >
              {tweets.map((blog) => {
                return (
                  <SwiperSlide key={blog.image}>
                    <div className="TweetBox" key={blog.id}>
                      <TwitterTweetEmbed
                        key={blog.id}
                        onLoad={function noRefCheck() {}}
                        tweetId={blog.id}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div
              style={{
                textAlign: "right",
                padding: 20,
                fontSize: 20,
                color: "#41faa4",
                width: "100%",
              }}
              onClick={() => {
                blogSwiperRef.current.swiper.slideNext();
              }}
            >
              <i className="fas fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
