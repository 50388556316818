import "../Contract.css";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  db,
  ref,
  onValue,
  query,
} from "./FirebaseSetup";
export default function GenerateContract() {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#fff";
    document.body.style.backgroundImage = "none";
    document.body.style.color = "#000";
    document.body.style.fontSize = "13px";
  });
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get("id");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [jurisCity, setJurisCity] = useState("");
  const [businessCountry, setBusinessCountry] = useState("");
  const [headquarters, setHeadQuarters] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [dateofbirth, setDateOfBirth] = useState("");
  const [countryofbirth, setCountryOfBirth] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [idType, setIdType] = useState("");
  const [idIssuer, setIdIssuser] = useState("");
  const [idIssueDate, setIdIssueDate] = useState("");
  const [idIssuerEmail, setIdIssuerEmail] = useState("");
  const [signature, setSignature] = useState("");
  const [contractGenrated, setContractGenerate] = useState(false);
  const [contractNumber, setContractNumber] = useState("-");
  const getUserData = () => {
    const mobileCount = query(ref(db, "users/" + userId));
    onValue(mobileCount, (snapshot) => {
      const data = snapshot.val();
      if (data === null) {
        // createNewMobileEntry();
      } else {
        setFirstName(data.firstname);
        setLastName(data.lastname);
        setDateOfBirth(data.dateofbirth);
        setCountryOfBirth(data.countryofbirth);
        setIdNumber(data.idNumber);
        setIdType(data.idType);
        setIdIssuser(data.idIssuer);
        setIdIssueDate(data.idIssueDate);
        setIdIssuerEmail(data.idIssuerEmail);
        setCompanyName(data.company);
        setBusinessCountry(data.businessCountry);
        setEmail(data.email);
        setHeadQuarters(data.headquarters);
        setJurisCity(data.jurisCity);
        setAddress(data.address);
        setSignature(data.signature);
        setContractNumber(data.contractNumber);
        setTimeout(function(){
            setContractGenerate(true);
        },1000);
        setTimeout(function(){
            window.print();
        },2000);
 
    }
    });
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return (
    <>
    {
        (!contractGenrated) ? <>
            <div className="generator">
            <center style={{
                paddingTop:100,
                fontSize:20,
                color:'#fff'
            }}>
                <i className="fas fa-spinner fa-spin"></i>&nbsp;
                Generating contract, please wait..
            </center>
    </div>

        </>:
        <>
        </>
    }
      <div
        className="fullpage"
        style={{
          paddingTop: 300,
        }}
      >
        <div className="heading">referral agreement</div>
        <strong>{contractNumber}</strong>
        <br />
        <br />
        Concluded on
        <br />
        <strong>___________</strong>
        <br />
        <br />
        Concluded by and between
        <br />
        <br />
        <strong>EMORYA IT S.R.L.</strong>
        <br />
        (as Beneficiary)
        <br />
        and
        <br />
        <br />
        <strong>{companyName}</strong>
        <br />
        (as Referrer)
      </div>
      <center>
        <div className="contractContainer">
          <table className="contractTable">
            <tr>
              <td></td>
              <td>
                This Referral Agreement (the <strong>“Agreement”</strong>) is
                entered into on [•] 2023 (the <strong>”Signing Date”</strong>)
                by and among:
              </td>
            </tr>
            <tr>
              <td>(1) </td>
              <td>
                <strong>EMORYA IT S.R.L.</strong>, a limited liability company
                incorporated under the laws of Romania, with headquarters at Sag
                Village, 34A LXII Street, Sag Commune, Timis Count, registered
                with the Trade Registry under no J35/368/2023 email: partner@emorya.com, duly
                represented by Mr. Oliviu – Florin Jurjica, as Director (the
                <strong>“Beneficiary”</strong>) and
              </td>
            </tr>
            <tr>
              <td>(2) </td>
              <td>
                <strong>{companyName}</strong>, a limited liability company
                incorporated under the laws of{" "}
                <strong>
                  {jurisCity},{businessCountry}
                </strong>
                , with headquarters at{" "}
                <strong>
                  {headquarters},{businessCountry}
                </strong>
                , registered with the trade registry office under no.{" "}
                <strong>{address}</strong>, email <strong>{email}</strong>, duly
                represented by{" "}
                <strong>
                  {firstname}
                  {lastname}
                </strong>
                , in his/her capacity as Director (the{" "}
                <strong>“Referrer”</strong>)
              </td>
            </tr>
            <tr>
              <td>(3) </td>
              <td>/</td>
            </tr>
            <tr>
              <td>(4) </td>
              <td>
                {lastname},{firstname} citizen, born on {dateofbirth} in{" "}
                {countryofbirth}, residing in {address}, identified with ID card
                series {idType} no. {idNumber} issued by {idIssuer} in &nbsp;
                {idIssueDate}, email {idIssuerEmail} (the{" "}
                <strong>“Referrer”</strong>)
              </td>
            </tr>
            <tr>
              <td>(5) </td>
              <td>
                (Together referred to as the “Parties” and individually a
                “Party”)
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <br />
                <br />
                <strong>WHEARAS</strong>
              </td>
            </tr>
            <tr>
              <td>(A)</td>
              <td>
                The Beneficiary intends to raise the exposure of the Project on
                a worldwide scale by creating affiliations with relevant figures
                in the fitness and wellness industry;
              </td>
            </tr>
            <tr>
              <td>(B)</td>
              <td>
                The Referrer, while not regularly performing the Referral
                Activity, has experience in the sports industry and knows
                multiple individuals that may be interested in the Project or
                are involved in activities directly or indirectly connected to
                the Project.
                <br />
                <br />
                <strong>THEREFORE</strong>, in consideration of the promises
                herein contemplated, and of each Party’s intention as to being
                legally bound hereby, they agree as follows:
              </td>
            </tr>
            <tr>
              <td>1.</td>
              <td>Definitions</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  <tr>
                    <td>1.1</td>
                    <td>
                      In this Agreement, the following capitalized terms shall
                      have the following meanings, unless the context requires
                      another interpretation, or it is otherwise stated:
                      <br />
                      <br />
                      <table className="contractTableInner">
                        <tr>
                          <td>2</td>
                          <td>“$EMR Token”</td>
                          <td>3</td>
                          <td>
                            means the Cryptocurrency type ESDT vested with
                            several utilities and functionalities as established
                            by its Issuer;
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>“Applicable Law”</td>
                          <td>5</td>
                          <td>
                            means any law, statute, regulation, code, ordinance,
                            norm, court decision, order, decree of Romania or
                            any other normative or administrative act in Romania
                            or any other decision enacted by or requirement or
                            recommendation issued by a public authority or any
                            interpretation or application of the above by a
                            public authority, provided that any of the above has
                            a binding effect by itself;{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>”Blockchain”</td>
                          <td>7</td>
                          <td>
                            is a system of recording information, in a way that
                            makes it difficult or impossible to change, hack, or
                            cheat the system, in general using cryptography and
                            being duplicated and distributed across the entire
                            network of computer systems on the Blockchain.
                          </td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>“Business Days”</td>
                          <td>9</td>
                          <td>
                            means a day (other than Saturday or Sunday) when the
                            banks are open for business in Romania;
                          </td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>“Emorya App”</td>
                          <td>11</td>
                          <td>
                            stands for the mobile application specially designed
                            to operate on a decentralized network (e.g.
                            Blockchain) with the primary objective to incentive
                            its users to engage in sports activities,
                            particularly by rewarding exercising and calory
                            burn.
                          </td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>“Interested Persons”</td>
                          <td>13</td>
                          <td>
                            means any individuals that may be willing to access
                            and use the Emorya App;
                          </td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>“Issuer”</td>
                          <td>15</td>
                          <td>
                            represents the issuer of the $EMR Token and $EMRS
                            Token;{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>16</td>
                          <td>“Personal IR”</td>
                          <td>17</td>
                          <td>
                            means the all the personal information related to
                            the Referrer, such as, image, name, professional and
                            academic achievements, other professional and
                            business details;{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>18</td>
                          <td>“Private Key”</td>
                          <td>19</td>
                          <td>
                            means the sequence of characters associated with an
                            Electronic Wallet required to perform any operation
                            with respect to any digital assets contained
                            therein.
                          </td>
                        </tr>
                        <tr>
                          <td>20</td>
                          <td>“Project”</td>
                          <td>21</td>
                          <td>
                            stands for the innovative blockchain project titled
                            “Emorya” designed for the fitness and wellness
                            industry which revolves around the Emorya App, the
                            $EMR Token and $EMRS Token, each with different
                            utilities within the Emorya ecosystem.
                          </td>
                        </tr>
                        <tr>
                          <td>22</td>
                          <td> “Referral Activity”</td>
                          <td>23</td>
                          <td>
                            means the activity of advertising the Project, as
                            well as onboarding and engaging the Interested
                            Persons to access and use the Emorya App;{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>24</td>
                          <td>“Referral Link“</td>
                          <td>25</td>
                          <td>
                            is the special link afforded to the Referrer to
                            carry out the Referral Activity and which grants
                            exclusive access to download and use the Emorya App
                            for the Interested Persons;{" "}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>2.</td>
              <td>
                <strong>Subject – matter of the Agreement</strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  <tr>
                    <td>2.1</td>
                    <td>
                      The Parties agree that the Referrer will conduct the
                      Referral Activity in favour of the Beneficiary, as defined
                      above, in exchange for the Compensation.
                    </td>
                  </tr>
                  <tr>
                    <td>2.2</td>
                    <td>
                      The Parties expressly agree that the Referrer is not a
                      proxy or employee of the Beneficiary, each party acting
                      independently of each other. Thus, the Referrer shall have
                      no power to represent or act on behalf of the Beneficiary
                      in relation to any third parties or public authorities.
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                <strong>Duration of the Agreement</strong>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                <strong>
                  The term of this Agreement shall commence on the Signing Date
                  and shall continue until [●], unless it is otherwise extended
                  or terminated as per the provisions stipulated herein.
                </strong>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                <strong>Compensation</strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  <tr>
                    <td>5.1</td>
                    <td>
                      In consideration of the Referral Activity, the Beneficiary
                      shall provide the Referrer with exclusive access to the
                      Emorya App, a Referral Link, as well as other benefits
                      that may be granted during the term of this Agreement (the
                      ”Compensation”).
                    </td>
                  </tr>
                  <tr>
                    <td>5.2</td>
                    <td>
                      No other form of compensations, benefits and/or
                      remunerations shall be granted to the Referrer for the
                      Referral Activity, unless the Parties agree otherwise.
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>
                <strong>
                  Obligations, limitation and extent of liability of the Parties
                </strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  <tr>
                    <td>6.1</td>
                    <td>
                      While conducting the Referral Activity, the Referrer
                      undertakes to observe the following obligations:
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <table className="contractTabOne">
                        <tr>
                          <td>6.1.1</td>
                          <td>
                            refrain from initiating any negotiations or enter
                            into any contracts or agreements with the Interested
                            Persons in relation to the $EMR Tokens or the
                            Project on behalf of the Beneficiary;
                          </td>
                        </tr>
                        <tr>
                          <td>6.1.2</td>
                          <td>
                            refrain from providing information, advice or
                            guarantees to the Interested Persons regarding the
                            activity, functionalities or the value of the $EMR
                            Token or $EMRS Token and/or the Project;
                          </td>
                        </tr>
                        <tr>
                          <td>6.1.3</td>
                          <td>
                            preclude from charging or receiving
                            directly/indirectly any amount of money or any other
                            benefits from the Interested Persons;
                          </td>
                        </tr>
                        <tr>
                          <td>6.1.4</td>
                          <td>
                            abstain from representing or misleading, on purpose
                            or by mistake, any third party that he is an
                            employee or representative of the Beneficiary;
                          </td>
                        </tr>
                        <tr>
                          <td>6.1.5</td>
                          <td>
                            preserve the strict confidentiality of all the
                            information provided by the Beneficiary, for an
                            undetermined period of time, including after the
                            termination of this Agreement;
                          </td>
                        </tr>
                        <tr>
                          <td>6.1.6</td>
                          <td>
                            refrain from disclosing to the public or third
                            parties any information with respect to the
                            Beneficiary, its activity, partners or offered
                            services, especially but without limited to the
                            information regarding the launching of various
                            products or functionalities which may impact the
                            Beneficiary’s activity or the Project (including the
                            $EMR Token or the $EMRS Token), without the
                            Beneficiary’s prior written approval;
                          </td>
                        </tr>
                        <tr>
                          <td>6.1.7</td>
                          <td>
                            to refrain from performing any actions which might
                            harm or otherwise damage the Beneficiary’s interests
                            or image.
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>6.2</td>
                    <td>
                      The Referrer expressly understands and assumes that $EMR
                      Tokens may be used in the manner and according to the
                      utilities or purposes established and offered by its
                      Issuer, such as, but not limited to, those available
                      within the Emorya App or ecosystem, which are under
                      continuous development and improvement, and the Referrer
                      expressly understands and assumes the such circumstances.
                    </td>
                  </tr>
                  <tr>
                    <td>6.3</td>
                    <td>
                      The Parties expressly agree that the Referrer shall bear
                      all expenses incurred by performing the Referral Activity,
                      unless such expenses have been pre-approved by the
                      Beneficiary.
                    </td>
                  </tr>
                  <tr>
                    <td>6.4</td>
                    <td>
                      The Referrer acknowledges and agrees that all intellectual
                      property rights, including but not limited to the name,
                      trademarks, logo, and any associated intellectual property
                      (collectively referred to as "Intellectual Property")
                      belonging to the Beneficiary, shall remain the exclusive
                      property of Emorya.
                    </td>
                  </tr>
                  <tr>
                    <td>6.5</td>
                    <td>
                      The Referrer shall not, directly or indirectly, engage in
                      any activities that may harm, infringe upon, or dilute the
                      Intellectual Property rights of the Beneficiary. This
                      includes, but is not limited to, unauthorized use,
                      reproduction, modification, distribution, or
                      misrepresentation of the Intellectual Property.
                    </td>
                  </tr>
                  <tr>
                    <td>6.6</td>
                    <td>
                      The Referrer acknowledges and agrees that any use of
                      Beneficiary’s Intellectual Property shall be strictly
                      limited to the scope of this Agreement.
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                <strong>Personal IR</strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  <tr>
                    <td>7.1</td>
                    <td>
                      The Referrer grants the Beneficiary, a royalty-free,
                      non-exclusive, license to use the Personal IR for
                      commercial purposes, including, but not limited to use,
                      distribution, reproduction, lending, assignment, import,
                      publication on any communication channel, etc. and is
                      expressly authorized to perform any amendments,
                      transformation, publication, editing, recording,
                      disclosure, improvement, correction and dissemination of
                      the work, etc., without any additional consent in this
                      respect, all over the world, during the term of this
                      Agreement and for a period of maximum 1 year after the
                      termination of the herein.
                    </td>
                  </tr>
                  <tr>
                    <td>7.2</td>
                    <td>
                      The Beneficiary may use the Referrer's Personal IR in
                      various advertising materials, including but not limited
                      to print media, digital media, social media, websites,
                      press releases, and other promotional channels. The
                      purpose of such usage shall be to promote and highlight
                      the Referrer's involvement and support in the Project.
                    </td>
                  </tr>
                  <tr>
                    <td>7.3</td>
                    <td>
                      The Referrer acknowledges and agrees that the Beneficiary
                      may edit, modify, or enhance the Referrer's Image as
                      necessary for advertising and promotional purposes,
                      provided that such modifications do not materially distort
                      or misrepresent the Referrer's likeness or identity.
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>
                <strong>Confidentiality</strong>{" "}
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>
                <strong>
                  The Parties shall not disclose, or wilfully or negligently
                  permit the disclosure of, any of the terms of this Agreement
                  to any third party without the prior written agreement of the
                  other Party, except for: (i) to the extent necessary to comply
                  with any legal obligation or legal requirement or during a
                  court proceeding; (ii) to the extent necessary to comply with
                  any requirements of any relevant stock exchange or other
                  regulatory, governmental or official body; (iii) to the extent
                  necessary to comply with or give effect to the terms of this
                  Agreement; (iv) to any authority for taxation, rating or
                  registration purposes; (v) to the professional advisers of
                  either of the Parties who need to know such details and who
                  have first agreed to be bound by the provisions of this
                  Clause; or (vi) to the extent they are already in the public
                  domain, otherwise than as a result of a breach of this Clause.
                </strong>
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>
                <strong>Personal Data Protection</strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  <tr>
                    <td>10.1</td>
                    <td>
                      Each Party is independently liable for observing the
                      applicable legal requirements for the lawful processing of
                      personal data in the context of its activities undergone
                      for the purposes of this Agreement. If one Party discloses
                      any personal data to the other Party, other than as
                      provided by the Agreement and necessary for the
                      performance thereof, these data shall be deleted as per
                      the internal regulations of the receiving Party and the
                      receiving Party will not assume any liability for this
                      processing, while any unwanted disclosure or other
                      incidents are in the sole responsibility of the disclosing
                      Party, who will also hold harmless of and indemnify the
                      receiving Party for any damage.
                    </td>
                  </tr>
                  <tr>
                    <td>10.2</td>
                    <td>
                      Personal data of the Parties’ representatives / contact
                      persons / employees
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <table className="contractTabOne">
                        <tr>
                          <td>10.2.1</td>
                          <td>
                            During the performance of this Agreement, each Party
                            may collect, store and use several categories of
                            personal data, including name, telephone number,
                            e-mail address, signature, related to the other
                            Party’s representatives or employees or other
                            persons representing that Party. The data may be
                            collected from the other Party or directly from the
                            data subject. The processing of the above personal
                            data is necessary in order to allow the Parties to
                            enter into and perform this Agreement.
                          </td>
                        </tr>
                        <tr>
                          <td>10.2.2</td>
                          <td>
                            To ensure the timely, transparent and meaningful
                            information of the data subjects, as required by the
                            General Data Protection Regulation (EU) 2016/679,
                            each Party will prepare and will be responsible for
                            the content of its own information notice regarding
                            the processing of personal data it performs with
                            respect to the other Party’s representatives /
                            employees and/or other persons representing that
                            Party. Each of the Parties undertakes to communicate
                            to each of the data subjects who acts in its name,
                            during the term of this Agreement, the information
                            notice issued by the other Party and made available
                            by the other Party, as well as any amendment and/or
                            update thereof (communicated in writing, including
                            in electronic form, by the issuing Party of the
                            information notice), within 30 calendar days from
                            the date the respective personal data was
                            communicated or, for the personal data already
                            communicated and processed, within 30 (thirty) days
                            from receipt of the notice or an indication where
                            the notice is available. Proof of such communication
                            will be delivered to the interested Party.
                          </td>
                        </tr>
                        <tr>
                          <td>10.2.3</td>
                          <td>
                            The personal data processed by each Party must be
                            accurate and updated. Each Party will keep the other
                            Party informed if there is any change regarding the
                            personal data processed as per the above, to the
                            extent the information is relevant for the
                            performance of this Agreement.
                          </td>
                        </tr>
                        <tr>
                          <td>10.2.4</td>
                          <td>
                            Each Party undertakes to comply with the
                            instructions that may be sent from time to time by
                            the other Party, in writing (including in electronic
                            form) as regards the information to be communicated
                            to the data subjects on behalf of each Party, for
                            the purpose of fulfilling the obligations from this
                            Agreement.
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                <strong>Notices</strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  <tr>
                    <td>11.1</td>
                    <td>
                      A notice, approval, consent or other communication in
                      connection with this Agreement:
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <table className="contractTabOne">
                        <tr>
                          <td>11.1.1</td>
                          <td>must be in writing;</td>
                        </tr>
                        <tr>
                          <td>11.1.2</td>
                          <td>
                            must be marked for the attention of the Parties’
                            representatives;
                          </td>
                        </tr>
                        <tr>
                          <td>11.1.3</td>
                          <td>
                            must be delivered by hand, by e-mail or courier
                            service to the address of the addressee, which is
                            specified under this section or if the addressee
                            notifies another address in written;
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>11.2</td>
                    <td>
                      A notice, approval, consent or other communication made
                      pursuant to the provisions of this Agreement takes effect
                      on the first business day of receipt at the relevant
                      address.
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                <strong>Force Majeure</strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  {" "}
                  <tr>
                    <td>12.1</td>
                    <td>
                      In this Agreement, <strong>“Force Majeure Event”</strong>{" "}
                      means extraordinary events or circumstances which neither
                      Party could either foresee or prevent by reasonable means
                      including but not limited to natural calamities, war,
                      revolution, riot, civil insurrection, acts of terrorism,
                      expropriation, nationalization, nuclear explosion,
                      radioactive or chemical contamination.
                    </td>
                  </tr>
                  <tr>
                    <td>12.2</td>
                    <td>
                      Each Party shall be relieved of liability for partial or
                      complete failure to perform its obligations under this
                      Agreement if such failure was due to Force Majeure Events
                      arising after the execution of this Agreement provided
                      that such relief from liability shall only relate to those
                      obligations directly affected by such Force Majeure Events
                      and such relief shall only subsist for as long as such
                      Force Majeure Events exist.
                    </td>
                  </tr>
                  <tr>
                    <td>12.3</td>
                    <td>
                      The Party relying on Force Majeure Events shall
                      immediately upon occurrence of such circumstances notify
                      the other Party in writing regarding the occurrence of the
                      respective circumstances.
                    </td>
                  </tr>
                  <tr>
                    <td>12.4</td>
                    <td>
                      If either of the Force Majeure Events continues for more
                      than 3 (three) months or at such time as it is reasonably
                      anticipated that Force Majeure Events will continue for
                      more than 3 (three) months, the Parties shall forthwith
                      enter into negotiations and agree such modifications to
                      this Agreement as are necessary to enable the Parties to
                      continue performance of their obligations under this
                      Agreement in a manner as close as possible to that
                      originally intended.
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>13</td>
              <td>
                <strong>Termination</strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  <tr>
                    <td>13.1</td>
                    <td>This Agreement may by terminated as follows:</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <table className="contractTabOne">
                        {" "}
                        <tr>
                          <td>a.</td>
                          <td>by written mutual consent of the Parties;</td>
                        </tr>
                        <tr>
                          <td>b.</td>
                          <td>
                            by termination due to non-performance by one Party
                            if the other Party fails to properly comply with its
                            obligations undertaken herein. The Termination shall
                            occur at the elapse of a 10-business day period from
                            the receipt by the Party in fault of a written
                            termination notice delivered by the other Party to
                            the headquarters or by e-mail, without any other
                            legal or court proceedings being required in this
                            respect.
                          </td>
                        </tr>
                        <tr>
                          <td>c.</td>
                          <td>
                            unilateral termination of the Agreement with a
                            notice period of 15 days in accordance with legal
                            provisions.
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>38</td>
              <td>
                <strong>Final provisions</strong>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="contractTabOne">
                  {" "}
                  <tr>
                    <td>38.1</td>
                    <td>
                      This Agreement is governed and shall be interpreted
                      pursuant to the provisions of the laws of Romania.
                    </td>
                  </tr>
                  <tr>
                    <td>38.2</td>
                    <td>
                      Any dispute (if not settled amicably) will be referred to
                      the Beneficiary’s headquarters competent courts of law in
                      Romania.
                    </td>
                  </tr>
                  <tr>
                    <td>38.3</td>
                    <td>
                      The Parties confirm that each clause of this Agreement has
                      been negotiated individually, each Party being assisted by
                      lawyers and professionals throughout the negotiation of
                      this Agreement.
                    </td>
                  </tr>
                  <tr>
                    <td>38.4</td>
                    <td>
                      Each of the Beneficiary and the Referrer hereby expressly
                      consent to each and every clause of this Agreement
                      including clauses which concern limitation of liability,
                      right to suspend the performance of obligations,
                      termination cases and its legal effects, limitation of
                      rights to raise exceptions or any other clauses which
                      could be qualified as standard clauses under Art. 1203 of
                      the Civil Code.
                    </td>
                  </tr>
                  <tr>
                    <td>38.5</td>
                    <td>
                      This Agreement shall not be construed as an adhesion
                      agreement and does not contain standard or unusual
                      Clauses, as defined under the Romanian legislation.
                    </td>
                  </tr>
                  <tr>
                    <td>38.6</td>
                    <td>
                      This Agreement shall produce legal effects to the benefit
                      of and be binding upon the Parties hereto and their
                      respective successors (including any successor by reason
                      of the merger or other corporate reorganization of such
                      Party).
                    </td>
                  </tr>
                  <tr>
                    <td>38.7</td>
                    <td>
                      All examples are inserted for convenience of understanding
                      and shall not be construed as limitations.
                    </td>
                  </tr>
                  <tr>
                    <td>38.8</td>
                    <td>
                      The rights and obligations stemming from this Agreement
                      may be transferred subject to the Beneficiary’s prior
                      approval. The Beneficiary is entitled to freely transfer
                      at any time any rights and obligations stemming from this
                      Agreement without the Referrer’s prior approval in this
                      respect.
                    </td>
                  </tr>
                  <tr>
                    <td>38.9</td>
                    <td>
                      This Agreement may be amended or supplemented by the
                      Parties’ written consent, namely by concluding an express
                      addendum in this respect.
                    </td>
                  </tr>
                  <tr>
                    <td>38.10</td>
                    <td>
                      Should any provision of this Agreement be deemed invalid,
                      illegal or unenforceable for any reason it shall not
                      affect the legality, validity and enforceability of the
                      remaining provisions hereof and the Parties undertake to
                      amend, supplement or substitute all and any such invalid,
                      illegal or unenforceable provisions with legal,
                      enforceable and valid provisions which would produce as
                      near as may be possible the economic result previously
                      intended by the Parties without renegotiation of any
                      material provisions of this Agreement.
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>39</td>
              <td>
                <strong>
                  IN WITNESS WHEREOF, the Parties have lawfully signed this
                  Agreement on the Signing Date on two (2) original copies in
                  English, one for each Party by electronic correspondence, any
                  copy in whatever form (physical or digital) bearing the
                  signatures of both parties shall be considered an original.
                </strong>
              </td>
            </tr>

            <tr>
              <td>40</td>
              <td
                style={{
                  paddingTop: 50,
                }}
              >
                <table
                  style={{
                    width: "100%",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <strong>
                        Beneficiary
                        <br />
                        EMORYA IT
                      </strong>
                      <br />
                      <br />
                      <span
                        style={{
                          fontWeight: "normal",
                        }}
                      >
                        Oliviu – Florin Jurjica - Director
                      </span>
                      <br />
                      <div
                        style={{
                          height: 80,
                          borderBottomWidth: 1,
                          borderBottomColor: "#000",
                          borderBottomStyle: "dotted",
                          width: 200,
                          display: "inline-block",
                        }}
                      ></div>
                    </td>

                    <td
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <strong>
                        Referrer
                        <br />
                        {companyName}
                      </strong>
                      <br />
                      <br />
                      <span
                        style={{
                          fontWeight: "normal",
                        }}
                      >
                        {firstname} {lastname} - Director
                      </span>
                      <br />
                      <div
                        style={{

                          
                          width: 200,
                          display: "inline-block",
                        }}
                      >
                        {signature !== "" ? (
                          <>
                            <img alt="signature"
                              src={signature}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <div
            style={{
              height: 300,
            }}
          ></div>
        </div>
      </center>
    </>
  );
}
