import { useState, useRef } from "react";
import "../App.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "./Modal";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Team() {
  const teamSwiperRef = useRef(null);

  const [teamInnerData, setTeamInnerData] = useState(false);

  const getTeamItems = (team) => {
    setTeamInnerData(team);
  };

  const unSetTeamData = () => {
    setTeamInnerData(false);
  };

  const teamdata = [
    {
      image: "Oliviu-Jurjica.png",
      name: "Oliviu Jurjica",
      title: "CEO & CO-FOUNDER",
      facebook: "https://www.facebook.com/oliviu.jurjica",
      instagram: "https://www.instagram.com/oliviujurjica/",
      twitter: "https://x.com/JurjicaO?t=X82Qj2nvb2KO1TrXK7wotw&s=08",
      linkedin: "https://www.linkedin.com/in/oliviu-jurjica-30b262156/",
      about:
        "Entrepreneur, Fitness trainer and dietitian, Business advisor, Crypto investor and Professional trader.<br/><br/>Oliviu is one of the key members of Emorya Team and the most experienced member in Crypto Trading, he is also the man who sits behind the EMRS (Emorya Sport) concept.",
      skills: [
        {
          name: "Crypto Trading",
          percent: "95%",
        },
        {
          name: "Stock Market & Crypto Investments",
          percent: "98%",
        },
        {
          name: "Motivational Psychology",
          percent: "100%",
        },
        {
          name: "Leadership",
          percent: "100%",
        },
      ],
    },
    /*
    {
        "image": "Vasile-Draghici.png",
        "name": "Vasile Draghici",
        "title": "CFO & CO-FOUNDER",
        "facebook": "https://www.facebook.com/vasi.draghici",
        "instagram": "https://www.instagram.com/vasi_draghici/",
        "twitter": "",
        "linkedin": "https://www.linkedin.com/in/vasidraghici/",
        "about": "Mentor in Business Environment, Entrepreneur and General Manager of Primo Life Group Companies – Distributor of ShieldUP concept.<br/><br/>Vasile is a pivotal member of the Emorya Team and the most experienced Business member of us. His experience as an entrepreneur and his management skills are indispensable for the Emorya Project.",
        "skills": [
            {
                "name": "Management",
                "percent": "98%"
            },
            {
                "name": "Strategic Development",
                "percent": "95%"
            },
            {
                "name": "Financial Management",
                "percent": "97%"
            }
        ]
    },
    */
    {
      image: "Andrei-Zorila.png",
      name: "Andrei Zorilă",
      title: "Business Development Manager",
      facebook: "https://www.facebook.com/andrei.m.zorila/",
      twitter: "https://twitter.com/andreizorilatm",
      linkedin: "https://www.linkedin.com/in/andreizorilatm/",
      about:
        "Andrei is an accomplished professional with a diverse skill set that encompasses many areas of expertise. As a Cryptocurrency WEB3 Business Architect, Marketing Specialist, Content Creator, Web Designer, and NFT – Economics ops Strategist, he has a deep understanding of the complex intersection between technology, finance, and marketing.<br/><br/>In his role as a key team member of the Emorya Project, Andrei has made invaluable contributions as the designer and architect of the Emorya Ecosystem. His creative vision and technical expertise have helped to shape the project and bring it to life.<br/><br/>Overall, Andrei is a talented and dedicated professional who has played an integral role in the success of the Emorya Project. His expertise and vision have helped to position the project as a leader in the fast-evolving world of blockchain and cryptocurrency.",
      skills: [
        {
          name: "Strategic Planning",
          percent: "95%",
        },
        {
          name: "Business Architecture",
          percent: "90%",
        },
        {
          name: "Strategic Communication",
          percent: "70%",
        },
        {
          name: "Marketing",
          percent: "100%",
        },
        {
          name: "Web Design",
          percent: "97%",
        },
        {
          name: "Content Creation",
          percent: "95%",
        },
      ],
    },
    {
      name: "Radu Birta",
      title: "Marketing Advisor",
      image: "Radu-Birta.png",
      linkedin: "https://www.linkedin.com/in/radu-birta/",
      about:
        "As a founder and visionary of a Web3 marketing agency, I specialize in devising unique and impactful strategies to skyrocket adoption and growth for groundbreaking blockchain projects, shaping the future of digital engagement.",
      skills: [
        {
          name: "Web3",
          percent: "95%",
        },
        {
          name: "Digital Marketing",
          percent: "96%",
        },
        {
          name: "Community Growth",
          percent: "90%",
        },
      ],
    },
    {
      image: "Robert-Raut.png",
      name: "Robert Raut",
      title: "VP Mobile Application",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "https://www.linkedin.com/in/robert-cristian-raut/",
      about:
        "With More than 10 years of Experience in IT, Robert is one of the pioneers of “the new technological world” which makes him an Indispensable and valuable member of our Team.",
      skills: [
        {
          name: "Frontend Development",
          percent: "97%",
        },
        {
          name: "Angular",
          percent: "95%",
        },
        {
          name: "Flutter",
          percent: "92%",
        },
      ],
    },
    {
      name: "Kostas Tzoumpas",
      title: "Smart Contract Developer from Eldar Solutions",
      image: "Kostas-Tzoumpas.png",
      linkedin: "https://www.linkedin.com/in/kostas-tzoumpas/",
    },
    {
      name: "Hamza A. Malik",
      title: "Senior Product Engineer",
      image: "Hamza-A-Malik.png",
      linkedin: "https://www.linkedin.com/in/hamzamalik195/",
    },
    {
      name: "Vijit Singhai",
      title: "Frontend Developer from Parxlab",
      image: "Vijit.png",
      linkedin: "https://www.linkedin.com/in/vijitsinghai/",
    },
    {
      image: "Francisc-Radoi.png",
      name: "Francisc Radoi",
      title: "Sports Ambassador Manager",
      facebook: "https://www.facebook.com/radoifrancisc",
      instagram: "https://www.instagram.com/radoi_fry/",
      twitter: "",
      linkedin: "https://www.linkedin.com/in/radoi-francisc-0a370b164/",
      about:
        "Francisc is one of the first members of the project and the one who formed the core team of Emorya.<br/><br/>He is also a professional athlete and manager in sport activities. Francisc is also the main founder of the Emorya Team and the man who brought us all together.",
      skills: [
        {
          name: "Coaching",
          percent: "85%",
        },
        {
          name: "Business",
          percent: "90%",
        },
      ],
    },
    {
      name: "Bordei Alex",
      image: "Alex-Bordei.png",
      title: "Senior Flutter Developer",
    },
    {
      name: "Toderean Razvan",
      image: "Todo-Razvan.png",
      title: "Full-Stack Blockchain Engineer",
      facebook: "https://www.facebook.com/razvan.toderean.9",
      instagram: "https://www.instagram.com/razvantoderean?igsh=MTd5N2kxMXBqZ2Nieg%3D%3D&utm_source=qr",
      linkedin: "http://linkedin.com/in/razvan-toderean-6747a5193"
    },
    {
      image: "Alexandra-Loch.png",
      name: "Alexandra Loch",
      title: "PR & Marketing specialist & Content Creator",
      facebook: "https://www.facebook.com/AlexandraJurjica",
      instagram: "",
      twitter: "https://twitter.com/JurjicaAlexandr",
      linkedin: "https://www.linkedin.com/in/alexandra-loch-8b5775237",
      about:
        "With a degree in Advertising, Alexandra takes care of the marketing part of our project.<br>\nShe is also an entrepreneur.<br>\nShe has a very positive spirit and she suits best in our team.",
      skills: [
        {
          name: "Advertising",
          percent: "97%",
        },
        {
          name: "Marketing",
          percent: "94%",
        },
        {
          name: "Content Creation",
          percent: "89%",
        },
      ],
    },
    {
      image: "Leandro-Marcarian.png",
      name: "Leandro Marcarian",
      title: "Economist & Tokenomics Expert & Advisor",
      facebook: "",
      instagram: "",
      twitter: "",
      linkedin: "https://www.linkedin.com/in/leandro-marcarian-47867023/",
      about:
        "Leandro Is our Tokenomics Advisor, he is one of the members who responsible for the development of our Tokenomics and for the calculation of Emorya’s project sustainability.<br/><br/>He has a vast experience as developer for tokenomic models for crypto projects and also as an advisor and assistant for various stages of the crypto development projects.",
      skills: [
        {
          name: "Financial Advisory",
          percent: "98%",
        },
        {
          name: "Business Consulting",
          percent: "96%",
        },
        {
          name: "Finance Consulting",
          percent: "97%",
        },
        {
          name: "Market Research",
          percent: "95%",
        },
        {
          name: "Strategic Planning",
          percent: "99%",
        },
      ],
    },
    {
      name: "Marean Bogdan",
      title: "Junior Engineer & Customer Support",
      image: "Marean-Bogdan.png",
      facebook: "https://www.facebook.com/bogdy.marean/",
      linkedin: "https://www.linkedin.com/in/marean-bogdan-80438a2bb/",
      instagram: "https://www.instagram.com/bogdy_marean/"
    },
  ];

  return (
    <>
      {teamInnerData ? (
        <>
          <Modal
            teamInnerData={teamInnerData}
            unSetTeamData={unSetTeamData}
          ></Modal>
        </>
      ) : (
        <></>
      )}

      <div
        className="section TeamSection"
        id="team-section"
        style={{
          height: "auto",
        }}
      >
        <div className="row row90 teamContainer">
          <div className="col-sm-12 sports">
            <div className="TeamHeadingContainer">
              <div className="blueSubHeading">Our Team</div>
              <div className="whiteHeading">The Leadership Team</div>
              <div className="text">
                The Emorya Crypto Team combines a passion for sports, industry
                expertise & a proven record in finance, development, marketing &
                licensing.
              </div>
            </div>
          </div>

          <div
            className="col-sm-12 showOnDesktop"
            style={{
              textAlign: "center",
              padding: 0,
            }}
          >
            {teamdata.map((team) => {
              return (
                <div
                  className="teamBox"
                  style={{
                    cursor: team.about && team.about !== "" ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (team.about && team.about !== "") {
                      getTeamItems(team);
                    }
                  }}
                  key={team.image}
                >
                  <img
                    alt="Emorya"
                    src={require("../assets/team/" + team.image)}
                    style={{
                      width: "60%",
                    }}
                  />
                  <br />

                  {team.facebook && team.facebook !== "" ? (
                    <>
                      <a href={team.facebook} target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <></>
                  )}

                  {team.twitter && team.twitter !== "" ? (
                    <>
                      <a href={team.twitter} target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter"></i>
                      </a>
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <></>
                  )}

                  {team.instagram && team.instagram !== "" ? (
                    <>
                      <a href={team.instagram} target="_blank" rel="noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <></>
                  )}

                  {team.linkedin && team.linkedin !== "" ? (
                    <>
                      <a href={team.linkedin} target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </>
                  ) : (
                    <></>
                  )}

                  <br />
                  <strong>{team.name}</strong>
                  <div className="blueSubHeading">{team.title}</div>
                </div>
              );
            })}
          </div>

          <div
            className="mobileTeam"
            style={{
              marginBottom: 200,
              maxHeight: 400,
            }}
          >
            <Swiper
              ref={teamSwiperRef}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              scrollbar={{ draggable: true }}
            >
              {teamdata.map((team) => {
                return (
                  <SwiperSlide key={team.image}>
                    <div
                      className="teamBox"
                      onClick={() => {
                        if (team.about && team.about !== "") {
                          getTeamItems(team);
                        }
                      }}
                    >
                      <img
                        alt="Emorya"
                        src={require("../assets/team/" + team.image)}
                        className="teamPic"
                      />
                      <br />
                      {team.facebook && team.facebook !== "" ? (
                        <>
                          <a
                            href={team.facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          &nbsp;&nbsp;
                        </>
                      ) : (
                        <></>
                      )}

                      {team.twitter && team.twitter !== "" ? (
                        <>
                          <a
                            href={team.twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-twitter"></i>
                          </a>
                          &nbsp;&nbsp;
                        </>
                      ) : (
                        <></>
                      )}

                      {team.instagram && team.instagram !== "" ? (
                        <>
                          <a
                            href={team.instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                          &nbsp;&nbsp;
                        </>
                      ) : (
                        <></>
                      )}

                      {team.linkedin && team.linkedin !== "" ? (
                        <>
                          <a
                            href={team.linkedin}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </>
                      ) : (
                        <></>
                      )}

                      <br />
                      <strong>{team.name}</strong>
                      <br />
                      <div className="blueSubHeading">{team.title}</div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div
              style={{
                textAlign: "right",
                padding: 20,
                fontSize: 20,
                color: "#41faa4",
                width: "100%",
              }}
              onClick={() => {
                teamSwiperRef.current.swiper.slideNext();
              }}
            >
              <i className="fas fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
