import { useState, useEffect, useRef, useMemo } from "react";
import "../App.css";
export default function Contact(props) {
  const heading = props.heading;
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
  const saveForm = () => {
    if (name === "" || email === "" || message === "") {
      setThankyouText("Please enter all the fields to connect with us.");
      setThankyou(true);
    } else {
      var formdata = new FormData();
      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("message", message);
      formdata.append("contactemail", contactEmail);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      setSubmitText("Please wait..");
      fetch("https://parxfit.com/emorya/save-data.php", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          if (result.status === 1) {
            setName("");
            setEmail("");
            setMessage("");
            setThankyouText(
              "Thankyou " +
                name +
                " for connecting with us. We have received your query and our team will connect with you as soon as possible."
            );
            setThankyou(true);
            setSubmitText("Query Submitted");
          }
        })
        .catch((error) => {
          setThankyouText(
            "Unable to connect to our query server. Please try again later"
          );
          setThankyou(true);
          setSubmitText("Retry");
        });
    }
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [thankyou, setThankyou] = useState(false);
  const [submitText, setSubmitText] = useState("Submit");
  const [thankyouText, setThankyouText] = useState(
    "Thankyou " +
      name +
      " for connecting with us. We have received your query and our team will connect with you as soon as possible."
  );

  const sectionId = "contactSection";
  const sectionRef = useRef(null);
  const isInViewport1 = useIsInViewport(sectionRef);
  if (isInViewport1) {
    document.getElementById(sectionId).className =
      "section TeamSection animate__animated animate__fadeInUp";
  }
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );
    useEffect(() => {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
    return isIntersecting;
  }

  return (
    <>
      <div id={sectionId} className="section TeamSection" ref={sectionRef} style={{
        marginTop:(heading) ? 90 : 0
      }}>
        <div className="row row90 teamContainer" id="contact">
          {
            (heading) ? <>
          <div className="col-sm-12 sports">
            <div className="TeamHeadingContainer">
              <div className="blueSubHeading">Contact</div>
              <div className="whiteHeading">Get In Touch</div>
            </div>
          </div>
            
            </>:
            <>
            
            </>
          }

          <div
            className="col-sm-12 "
            style={{
              paddingTop: 30,
              textAlign: "center",
            }}
          >
            <div className="TeamHeadingContainer">
              <div className="form">
                <div className="formElement">
                  {thankyou ? (
                    <>
                      <div
                        className="whiteHeading"
                        style={{
                          fontSize: 15,
                          fontWeight: "normal",
                          color:
                            thankyouText.indexOf("Thankyou") !== -1
                              ? "#fff"
                              : "#b55e5e",
                        }}
                      >
                        {thankyouText}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <label>Your Name</label>
                  <input
                    type="text"
                    className="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="inputLine"></div>
                </div>

                <div className="formElement">
                  <label>Your Email</label>
                  <input
                    type="text"
                    className="input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="inputLine"></div>
                </div>

                <div className="formElement">
                  <label>Your Message</label>
                  <input
                    type="text"
                    className="input"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div className="inputLine"></div>
                </div>

                <div className="formElement formButton">
                  <div className="btn-green-wrapper">
                    <button
                      className="btn-green btn btn-sm"
                      onClick={() => {
                        saveForm();
                      }}
                    >
                      {submitText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
