import '../App.css';
import EarlyAccessHead from './EarlyAccessHead';
import Footer from './Footer';
import Header from "./Header";
import TweetBox from './TweetBox';

export default function EarlyAccess() {



    return (
        <>
            <Header homepage={false}></Header>
            <EarlyAccessHead></EarlyAccessHead>
            <TweetBox></TweetBox>
            <Footer></Footer>
        </>
    )

}