import { useState, useEffect, useRef, useMemo } from "react";
import "../App.css";
export default function Dapps() {
  const sectionId = "dappsSection";
  const sectionRef = useRef(null);
  const isInViewport1 = useIsInViewport(sectionRef);
  if (isInViewport1) {
    document.getElementById(sectionId).className =
      "section dappsSection animate__animated animate__fadeInUp";
  }
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );
    useEffect(() => {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
    return isIntersecting;
  }

  return (
    <>
      <div
        id={sectionId}
        className="section dappsSection"
        ref={sectionRef}
        style={{
          height: "auto",
        }}
      >
        <div className="row row90">
          <div className="col-sm-12 sports">
            <div className="daapsHeading">
              <div className="blueSubHeading">dApps</div>
              <div className="whiteHeading">Emorya Mobile App</div>
              <div className="text">
                Once you’ve entered our ecosystem, you can manage everything. By
                simply tracking your calories, burning process by running,
                walking, swimming, cycling, dancing, or working out activities
                on your usual fitness smart devices.
              </div>
            </div>
          </div>

          <div className="col-sm-12 tokenBox" style={{}}>
            <div className="row">
              <div className="col-sm-12 dApps">
                <div className="dAppstext">
                  Basically, with a smartphone, smartwatch, smart bracelet, and
                  an internet connection, no matter your age, you can
                  participate in the global Move-2-Earn programs and
                  marketplace.
                </div>
                <br />
                <br />

                <br />
              </div>
              <div className="col-sm-6 dApps">
                <div className="dAppsLine">
                  <i className="fas fa-circle gradColor"></i>&nbsp;&nbsp;Work
                  out physically to get rewards by burning your calories.
                </div>

                <div className="dAppsLine">
                  <i className="fas fa-circle gradColor"></i>
                  &nbsp;&nbsp;Activity monitoring with your own AI concierge
                  integrated.
                </div>

                <div className="dAppsLine">
                  <i className="fas fa-circle gradColor"></i>&nbsp;&nbsp;Easy
                  Understanding.
                </div>

                <div className="dAppsLine">
                  <i className="fas fa-circle gradColor"></i>&nbsp;&nbsp;Wallet
                  aggregation.
                </div>

                <div className="dAppsLine">
                  <i className="fas fa-circle gradColor"></i>
                  &nbsp;&nbsp;Professional Tools.
                </div>

                <div className="dAppsLine">
                  <i className="fas fa-circle gradColor"></i>&nbsp;&nbsp;No more
                  expensive fees
                </div>
                <br />
                <br />
              </div>

              <div className="col-sm-6">
                <img
                  alt="Emorya"
                  src={require("../assets/mobile-app.png")}
                  className="dappsImage"
                />
              </div>

              <div className="col-sm-12 downLoadSection">
                <div className="btn-green-wrapper">
                  <button
                    className="btn-green"
                    onClick={() => {
                      window.open("/#/early-access-whitelist", "_blank");
                    }}
                    style={{
                      backgroundColor: "transparent",
                      width: "100%",
                    }}
                  >
                    Get App (Coming Soon)
                  </button>
                </div>
                <div className="appIcons">
                  <a href="/#/early-access-whitelist" target="_blank">
                    <img alt="Emorya" src={require("../assets/apple.png")} />
                  </a>

                  <a href="/#/early-access-whitelist" target="_blank">
                    <img alt="Emorya" src={require("../assets/android.png")} />
                  </a>

                  <a href="/#/early-access-whitelist" target="_blank">
                    <img alt="Emorya" src={require("../assets/windows.png")} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
