import "../App.css";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Cookies from "js-cookie";
import { db, ref, onValue, query } from "./FirebaseSetup";
import { orderByKey } from "firebase/database";
export default function ContractLogin() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loginDisplay, setLoginDisplay] = useState(true);
  const [contracts, setContracts] = useState([]);
  const handleLogin = () => {
    if (username === "webmaster" && password === "Emr@0990") {
      Cookies.set("user", username, { expires: 7 });
      setLoginDisplay(false);
      getContracts();
      setUserName("");
      setPassword("");
    } else {
      alert("Invalid Credentials");
    }
  };
  const handleLogout = () => {
    Cookies.remove("user");
    setLoginDisplay(true);
  };
  const getContracts = () => {
    const allContracts = query(ref(db, "users"), orderByKey());
    onValue(allContracts, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        const dataArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setContracts(dataArray.reverse());
      }
    });
  };
  useEffect(() => {
    const cookValue = Cookies.get("user");
    if (cookValue) {
      setLoginDisplay(false);
      getContracts();
    } else {
      setLoginDisplay(true);
    }
  }, []);
  return (
    <>
      <Header homepage={false}></Header>
      <div
        className="headRectangle"
        style={{
          backgroundColor: "#162B86",
          height: "auto",
          minHeight: 1000,
        }}
      >
        {loginDisplay ? (
          <>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-4"
                style={{
                  paddingTop: 50,
                }}
              >
                <div className="text">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </span>
                  <br />
                  <div className="form">
                    <div className="formElement">
                      <label>Username</label>
                      <input
                        type="text"
                        className="input"
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <div className="inputLine"></div>
                    </div>
                    <div className="formElement">
                      <label>Password</label>
                      <input
                        type="password"
                        className="input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="inputLine"></div>
                    </div>
                    <div className="btn-green-wrapper">
                      <button
                        className="btn-green btn btn-sm"
                        style={{
                          width: 200,
                        }}
                        onClick={() => {
                          handleLogin();
                        }}
                      >
                        Login&nbsp;&nbsp;
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style={{
                  textAlign: "right",
                  paddingTop: 50,
                }}
              >
                <button
                  className="btn-green btn btn-sm"
                  style={{
                    width: 170,
                    fontSize: 9,
                    padding: 10,
                  }}
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  Logout
                </button>
              </div>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style={{
                  paddingTop: 20,
                }}
              >
                <div className="text">
                  <span
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Contracts
                  </span>
                </div>
                <table className="table dataTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Country</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Company Name</th>
                      <th>Person Name</th>
                      <th>Contract</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contracts.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.country}</td>
                        <td>{item.mobile}</td>
                        <td>{item.email}</td>
                        <td>{item.company}</td>
                        <td>
                          {item.firstname} {item.lastname}
                        </td>
                        <td>
                          {item.signature && item.signature !== "" ? (
                            <>
                              <button
                                className="btn-green btn btn-sm"
                                style={{
                                  width: 170,
                                  fontSize: 9,
                                  padding: 10,
                                }}
                                onClick={() => {
                                  window.open(
                                    "#/generate-contract?id=" + item.id
                                  );
                                }}
                              >
                                Download Contract&nbsp;&nbsp;
                                <i className="fas fa-arrow-right"></i>
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer></Footer>
    </>
  );
}
