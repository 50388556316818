import "../App.css";
import { whatIsEmoryaBoxs } from "../utils/constant";

export default function WhatIsEmorya() {
  return (
    <>
      <div className="section">
        <div className="row row90 whatisEmoryaSection">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 whatIsFirst">
            <div className="blueSubHeading">What is Emorya $EMR</div>
            <div className="text">
              The First Global Project of this kind that is conceived to give
              you the opportunity to earn rewards by burning your calories.
              <br />
              <br />
              Emorya is created on{" "}
              <span className="green-color">MultiversX Blockchain.</span>
              <br />
              <br />
              An automatic burn mechanism is planned to be implemented for every
              trade performed within the liquidity pool.
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 emoryBoxsContainer">
            {whatIsEmoryaBoxs.map(({ id, img, label, bottomText }) => {
              return (
                <div
                  key={id}
                  className={`emoryaBox position-relative emoryBox-${id}`}
                >
                  <img src={img} alt="what is emorya" />
                  <div className="position-absolute d-flex justify-content-center align-items-center">
                    <span className="">{label || bottomText}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
