import "../App.css";
export default function BurnToMillion() {
  return (
    <>
      <div className="section burnSection">
        <div className="row row90">
          <div className="col-sm-6 burnLogoContainer"></div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 burnTextContainer">
            <div className="burnText">
              <div className="blueSubHeading">
                What is Burn to Million Concept
              </div>
              <div className="text">
                Emorya plans to limit the supply of{" "}
                <span className="green-color">EMR to 1 Million tokens</span>,
                via an automatic burn mechanism of every trade performed within
                the liquidity pool.
                <br />
                <strong className="strong-txt">
                  *the taxes are set to be reduced to 3% after the circulating
                  supply reaches 1 million tokens
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
