
function AppVideo() {
  return (
    <>
      <div className="section">
      <div
        className="headRectangle animate__animated animate__fadeInUp"
        style={{
          minHeight: '70vh',
          marginTop: 0,
          width:'90%'
        }}
      >
        <div className="row">
            <div
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 videoBox"
            >
              <iframe
                height="100%"
                width="100%"
                src="https://www.youtube.com/embed/hmd_hZ_umTY?si=Eoh6GUvgfYWrPdW1"
                title="Emorya Finance | Live 2 Earn"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AppVideo;
