import "../App.css";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Countries, CountryNames } from "./Countries";
import {
  firebase,
  auth,
  db,
  set,
  ref,
  onValue,
  push,
  query,
  orderByChild,
  equalTo,
} from "./FirebaseSetup";
import SignaturePad from "signature_pad";
export default function Contract() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const countries = JSON.parse(CountryNames);
  const countrydata = JSON.parse(Countries);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);
  const [formFilled, setFormFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const [final, setfinal] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [formError, setFormError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [jurisCity, setJurisCity] = useState("");
  const [businessCountry, setBusinessCountry] = useState("");
  const [headquarters, setHeadQuarters] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [dateofbirth, setDateOfBirth] = useState("");
  const [countryofbirth, setCountryOfBirth] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [idType, setIdType] = useState("");
  const [idIssuer, setIdIssuser] = useState("");
  const [idIssueDate, setIdIssueDate] = useState("");
  const [idIssuerEmail, setIdIssuerEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [sendingtext, setSendingText] = useState("Send Verification Otp");
  const [contractGenerated, setContractGenerated] = useState(false);
  const getCountryCode = (item) => {
    setCountry(item);
    setCountryCode("+" + countrydata[item]);
  };
  let verify;
  const sendOtp = () => {
    if (mobile === "" || name === "" || country === "") {
      setMobileError("Please fill all the fields");
    } else {
      setSendingText("Verifying..");
      verify = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
      });
      var toCheckNumber = "+" + countryCode + mobile;
      setSendingText("Sending Otp..");
      auth
        .signInWithPhoneNumber(toCheckNumber, verify)
        .then((result) => {
          verify.clear();
          verify = null;
          setfinal(result);
          setCodeSent(true);
        })
        .catch((err) => {
          alert(err);
        });
    }
  };
  const confirmOtp = () => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then((result) => {
        setCodeVerified(true);
        checkUserExistance();
      })
      .catch((err) => {
        setOtpError("Invalid OTP. Please try again.");
      });
  };
  const goToSignature = () => {
    if (
      firstname === "" ||
      lastname === "" ||
      dateofbirth === "" ||
      countryofbirth === ""
    ) {
      setFormError("Please enter all information about the director");
    } else if (
      idNumber === "" ||
      idType === "" ||
      idIssuer === "" ||
      idIssueDate === "" ||
      idIssuerEmail === ""
    ) {
      setFormError(
        "Please enter all information about the director identity information"
      );
    } else if (
      companyName === "" ||
      email === "" ||
      businessCountry === "" ||
      headquarters === "" ||
      jurisCity === "" ||
      address === ""
    ) {
      setFormError("Please enter all information about your company");
    } else {
      saveContractInformation();
    }
  };
  var canvas;
  var signaturePad;
  const setUpSignature = () => {
    canvas = document.getElementById("signature-pad");
    signaturePad = new SignaturePad(canvas);
    var clearButton = document.querySelector(".btn-clear");
    clearButton.addEventListener("click", function (e) {
      signaturePad.clear();
    });
    var saveButton = document.querySelector(".save-button");
    saveButton.addEventListener("click", function (e) {
      getUserCount();
    });
  };
  var mailSent = false;
  var cNumber = 0;
  const getUserCount = () => {
    const mobileCount = query(ref(db, "users"));
    onValue(mobileCount, (snapshot) => {
      const data = snapshot.val();
      if (data == null) {
        cNumber = 0;
      } else {
        var values = Object.values(data);
        cNumber = values.length;
        if (cNumber < 10) {
          cNumber = "000" + cNumber;
        } else if (cNumber >= 10 && cNumber < 100) {
          cNumber = "00" + cNumber;
        } else if (cNumber >= 100 && cNumber < 1000) {
          cNumber = "0" + cNumber;
        }
        var thisDate = new Date();
        var year = thisDate.getFullYear();
        var contractNumber = "EMR/" + year + "/" + cNumber;
        var contractDate =
          thisDate.getDate() + "-" + (thisDate.getMonth() + 1) + "-" + year;
        var d = signaturePad.toDataURL();
        const userSignRef = ref(db, "users/" + userId);
        if (!contractGenerated) {
          set(userSignRef, {
            contractDate: contractDate,
            contractNumber: contractNumber,
            signature: d,
            name: name ? name : "-",
            mobile: countryCode + mobile,
            country: country ? country : "-",
            company: companyName ? companyName : "-",
            email: email ? email : "-",
            address: address ? address : "-",
            jurisCity: jurisCity ? jurisCity : "-",
            businessCountry: businessCountry ? businessCountry : "-",
            headquarters: headquarters ? headquarters : "-",
            firstname: firstname ? firstname : "-",
            lastname: lastname ? lastname : "-",
            dateofbirth: dateofbirth ? dateofbirth : "-",
            countryofbirth: countryofbirth ? countryofbirth : "-",
            idNumber: idNumber ? idNumber : "-",
            idType: idType ? idType : "-",
            idIssuer: idIssuer ? idIssuer : "-",
            idIssueDate: idIssueDate ? idIssueDate : "-",
            idIssuerEmail: idIssuerEmail ? idIssuerEmail : "-",
          }).then(() => {
            if (!contractGenerated) {
              setContractGenerated(true);
              notifyOliviu();
              window.open("#/generate-contract?id=" + userId);
            }
          });
        }
      }
    });
  };

  const notifyOliviu = () => {
    if (!mailSent) {
      mailSent = true;
      var formdata = new FormData();
      formdata.append("name", firstname + lastname);
      formdata.append("email", email);
      formdata.append("phone", countryCode + mobile);
      formdata.append("company", companyName);
      formdata.append("location", businessCountry);
      formdata.append("contactemail", process.env.REACT_APP_CONTACT_EMAIL);
      formdata.append(
        "link",
        "https://emorya.com/#/generate-contract?id=" + userId
      );
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(
        "https://parxfit.com/emorya/contract-notification.php",
        requestOptions
      ).then((response) => response.text());
    }
  };

  const checkUserExistance = () => {
    const mobileCount = query(
      ref(db, "users"),
      orderByChild("mobile"),
      equalTo(countryCode + mobile)
    );
    onValue(mobileCount, (snapshot) => {
      const data = snapshot.val();
      if (data == null) {
        createNewMobileEntry();
      } else {
        var values = Object.values(data);
        var keys = Object.keys(data);
        setUserId(keys[0]);
        setFirstName(values[0].firstname);
        setLastName(values[0].lastname);
        setDateOfBirth(values[0].dateofbirth);
        setCountryOfBirth(values[0].countryofbirth);
        setIdNumber(values[0].idNumber);
        setIdType(values[0].idType);
        setIdIssuser(values[0].idIssuer);
        setIdIssueDate(values[0].idIssueDate);
        setIdIssuerEmail(values[0].idIssuerEmail);
        setCompanyName(values[0].company);
        setBusinessCountry(values[0].businessCountry);
        setEmail(values[0].email);
        setHeadQuarters(values[0].headquarters);
        setJurisCity(values[0].jurisCity);
        setAddress(values[0].address);
        if (values[0].signature && values[0].signature !== "") {
          setContractGenerated(true);
        }
      }
    });
  };
  const createNewMobileEntry = () => {
    const userListRef = ref(db, "users");
    const newUserRef = push(userListRef);
    setUserId(newUserRef.key);
    set(newUserRef, {
      name: name,
      mobile: countryCode + mobile,
      country: country,
    });
  };
  const saveContractInformation = () => {
    const userDataRef = ref(db, "users/" + userId);
    set(userDataRef, {
      name: name ? name : "-",
      mobile: countryCode + mobile,
      country: country ? country : "-",
      company: companyName ? companyName : "-",
      email: email ? email : "-",
      address: address ? address : "-",
      jurisCity: jurisCity ? jurisCity : "-",
      businessCountry: businessCountry ? businessCountry : "-",
      headquarters: headquarters ? headquarters : "-",
      firstname: firstname ? firstname : "-",
      lastname: lastname ? lastname : "-",
      dateofbirth: dateofbirth ? dateofbirth : "-",
      countryofbirth: countryofbirth ? countryofbirth : "-",
      idNumber: idNumber ? idNumber : "-",
      idType: idType ? idType : "-",
      idIssuer: idIssuer ? idIssuer : "-",
      idIssueDate: idIssueDate ? idIssueDate : "-",
      idIssuerEmail: idIssuerEmail ? idIssuerEmail : "-",
    }).then(() => {
      setFormFilled(true);
      setTimeout(function () {
        setUpSignature();
      }, 500);
    });
  };
  useEffect(() => {
    //    getUserCount();
  }, []);
  return (
    <>
      <Header homepage={false}></Header>
      <div
        className="headRectangle animate__animated animate__fadeInUp"
        style={{
          minHeight: 900,
          marginTop: 100,
        }}
      >
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-7"
            style={{
              paddingTop: 50,
            }}
          >
            <div className="green-heading">
              <strong>
                Grow Your Community
                <br />
                And Earn Together!
              </strong>
            </div>
            <div className="text">
              <span
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                Through our unique referral link, provided by our official
                Emorya partners, sports clubs, and ambassadors can bring more
                people into the Emorya community and benefit at the same time.
              </span>
              <br />
              <br />
            </div>
            <div
              className="btn-green-wrapper"
              style={{
                marginTop: 10,
              }}
            ></div>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-5"
            style={{
              textAlign: "center",
              paddingTop: 50,
            }}
          >
            <img
              src={require("../assets/contract.png")}
              style={{
                width: "200px",
              }}
              alt="Emorya"
            />
          </div>
          <div
            className="col-sm-4"
            style={{
              paddingTop: 100,
            }}
          >
            <img
              src={require("../assets/exclusive.png")}
              style={{
                width: "100px",
              }}
              alt="Emorya"
            />
            <br />
            <br />
            <strong>Exclusive Access</strong>
            <br />
            <br />
            <span
              style={{
                fontSize: 13,
              }}
            >
              The Emorya app, which pays you for every calorie burned, can only
              be accessed through the referral link provided by your official
              Emorya partner.
            </span>
          </div>
          <div
            className="col-sm-4"
            style={{
              paddingTop: 100,
            }}
          >
            <img
              src={require("../assets/support.png")}
              style={{
                width: "100px",
              }}
              alt="Emorya"
            />
            <br />
            <br />
            <strong>Community Growth</strong>
            <br />
            <br />
            <span
              style={{
                fontSize: 13,
              }}
            >
              Each person who signs up through your referral link becomes a part
              of your community, bringing value and opportunities for all
              members.
            </span>
          </div>
          <div
            className="col-sm-4"
            style={{
              paddingTop: 100,
            }}
          >
            <img
              src={require("../assets/award.png")}
              style={{
                width: "100px",
              }}
              alt="Emorya"
            />
            <br />
            <br />
            <strong>10% for You</strong>
            <br />
            <br />
            <span
              style={{
                fontSize: 13,
              }}
            >
              Not only will you attract new members, but you will also receive
              10% of the rewards generated by each person who signs up through
              your referral link.
            </span>
          </div>
          <div
            className="col-sm-12"
            style={{
              paddingTop: 50,
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              So, together we can grow our community, earn, and have a positive
              impact on our health and those around us!
              <br />
              <br />
            </span>
            Start now by signing the contract with us and gain access to all
            these benefits.
          </div>
        </div>
      </div>
      <div
        className="contractHeading animate__animated animate__fadeInUp"
        style={{
          paddingTop: 0,
        }}
      >
        <div className="row">
          <div
            className="col-sm-6"
            style={{
              paddingBottom: 30,
            }}
          >
            <div className="green-heading">
              <strong>Contract Signing</strong>
            </div>
          </div>
        </div>
        {contractGenerated ? (
          <>
            <div className="row">
              <div className="col-sm-12">
                The contract for {companyName} is generated. Please download the
                contract from the button below and send a copy on{" "}
                <strong>business@emorya.com</strong> for further processing. If
                you wish to make any change or discuss any issue, drop your
                query on the same email address.
                <br />
                <br />
                <div className="btn-green-wrapper">
                  <button
                    className="btn-green btn btn-sm"
                    style={{
                      width: 250,
                    }}
                    onClick={() => {
                      window.open("#/generate-contract?id=" + userId);
                    }}
                  >
                    Download Contract
                    <i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {!codeSent ? (
              <>
                <div className="row">
                  <div className="col-sm-6">
                    {mobileError !== "" ? (
                      <>
                        <div className="error">{mobileError}</div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="form">
                      <div className="formElement">
                        <label>Your Name</label>
                        <input
                          type="text"
                          className="input"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <div className="inputLine"></div>
                      </div>
                      <div className="formElement">
                        <label>Your Country</label>
                        <select
                          className="input"
                          value={country}
                          onChange={(e) => getCountryCode(e.target.value)}
                        >
                          <option value="">Select Country</option>
                          {countries.map((thiscountry) => {
                            return (
                              <option value={thiscountry}>{thiscountry}</option>
                            );
                          })}
                        </select>
                        <div className="inputLine"></div>
                      </div>
                      <div className="formElement">
                        <label>Mobile Number</label>
                        <div className="codeHolder">{countryCode}</div>
                        <input
                          type="number"
                          className="input"
                          style={{
                            paddingLeft: 80,
                          }}
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                        <div className="inputLine"></div>
                      </div>
                      <div className="formElement formButton">
                        <div
                          style={{
                            textAlign: "center",
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <div id="recaptcha-container"></div>
                        </div>
                        <div className="btn-green-wrapper">
                          <button
                            className="btn-green btn btn-sm"
                            style={{
                              width: 250,
                            }}
                            onClick={() => {
                              sendOtp();
                            }}
                          >
                            {sendingtext}&nbsp;&nbsp;
                            <i className="fas fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {codeVerified ? (
                  <>
                    {formFilled ? (
                      <>
                        <div className="row">
                          <div
                            className="col-sm-12"
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <div className="formElement">
                              <label
                                style={{
                                  fontSize: 16,
                                }}
                              >
                                Place your signature here
                              </label>
                              <br />
                              <label>
                                Use your mouse or finger to sign within the box.
                                This signature will be used to presign your
                                contract.
                              </label>
                            </div>
                            <canvas
                              id="signature-pad"
                              className="signature-pad"
                              style={{
                                backgroundColor: "#fff",
                              }}
                            ></canvas>
                            <br />
                            <div
                              className="btn-clear"
                              id="btn-clear"
                              style={{
                                paddingTop: 10,
                                cursor: "pointer",
                              }}
                            >
                              <i className="fas fa-redo"></i>
                            </div>
                            <br />
                            <div className="btn-green-wrapper">
                              <button
                                className="btn-green btn btn-sm save-button"
                                style={{
                                  width: 150,
                                }}
                              >
                                Done&nbsp;&nbsp;
                                <i className="fas fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="formElement">
                                <label
                                  style={{
                                    fontSize: 16,
                                  }}
                                >
                                  Director Information
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Director First Name</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={firstname}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Director Last Name</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={lastname}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Date Of Birth</label>
                                <input
                                  type="date"
                                  className="input"
                                  value={dateofbirth}
                                  onChange={(e) =>
                                    setDateOfBirth(e.target.value)
                                  }
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Country Of Birth</label>
                                <select
                                  className="input"
                                  value={countryofbirth}
                                  onChange={(e) =>
                                    setCountryOfBirth(e.target.value)
                                  }
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((thiscountry) => {
                                    return (
                                      <option value={thiscountry}>
                                        {thiscountry}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div
                              className="col-sm-12"
                              style={{
                                paddingTop: 50,
                              }}
                            >
                              <div className="formElement">
                                <label
                                  style={{
                                    fontSize: 16,
                                  }}
                                >
                                  Govt. Approved Identity(Individuality)
                                  Information
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Identity No.</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={idNumber}
                                  onChange={(e) => setIdNumber(e.target.value)}
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Identity Type</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={idType}
                                  onChange={(e) => setIdType(e.target.value)}
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Issuing Orgnaization</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={idIssuer}
                                  onChange={(e) => setIdIssuser(e.target.value)}
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Issuing Year?</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={idIssueDate}
                                  onChange={(e) =>
                                    setIdIssueDate(e.target.value)
                                  }
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="formElement">
                                <label>
                                  Email Address of issuing authority
                                </label>
                                <input
                                  type="text"
                                  className="input"
                                  value={idIssuerEmail}
                                  onChange={(e) =>
                                    setIdIssuerEmail(e.target.value)
                                  }
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div
                              className="col-sm-12"
                              style={{
                                paddingTop: 50,
                              }}
                            >
                              <div className="formElement">
                                <label
                                  style={{
                                    fontSize: 16,
                                  }}
                                >
                                  Company Information
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="formElement">
                                <label>Your Company Name</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={companyName}
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Company Email</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Registered Country</label>
                                <select
                                  className="input"
                                  value={businessCountry}
                                  onChange={(e) =>
                                    setBusinessCountry(e.target.value)
                                  }
                                >
                                  <option value="">Select Country</option>
                                  {countries.map((thiscountry) => {
                                    return (
                                      <option value={thiscountry}>
                                        {thiscountry}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Headquarters City</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={headquarters}
                                  onChange={(e) =>
                                    setHeadQuarters(e.target.value)
                                  }
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="formElement">
                                <label>Jurisdiction City/State</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={jurisCity}
                                  onChange={(e) => setJurisCity(e.target.value)}
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="formElement">
                                <label>Full Office Address</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={address}
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                                <div className="inputLine"></div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              {formError !== "" ? (
                                <>
                                  <div className="error">{formError}</div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="formElement formButton">
                                <div className="btn-green-wrapper">
                                  <button
                                    className="btn-green btn btn-sm"
                                    style={{
                                      width: 250,
                                    }}
                                    onClick={() => {
                                      goToSignature();
                                    }}
                                  >
                                    Proceed
                                    <i className="fas fa-arrow-right"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form">
                          {otpError !== "" ? (
                            <>
                              <div className="error">{otpError}</div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="formElement">
                            <label>Enter Otp</label>
                            <input
                              type="text"
                              className="input"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                            />
                            <div className="inputLine"></div>
                          </div>
                          <div className="formElement formButton">
                            <div className="btn-green-wrapper">
                              <button
                                className="btn-green btn btn-sm"
                                style={{
                                  width: 250,
                                }}
                                onClick={() => {
                                  confirmOtp();
                                }}
                              >
                                Confirm Otp&nbsp;&nbsp;
                                <i className="fas fa-arrow-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Footer></Footer>
    </>
  );
}
