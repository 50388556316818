import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import tokenReleasePDF from '../../assets/documents/token-release.pdf';


export default function TokenRelease() {


    const navigate = useNavigate();

    useEffect(() => {
        window.open(tokenReleasePDF, '_blank');

        navigate('/');
    }, [navigate]);

}