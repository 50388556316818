import "../App.css";
export default function Calculator() {
  return (
    <>
      <div className="section TeamSection">
        <div className="row row90 teamContainer">
          <div className="col-sm-12 sports">
            <iframe
              title="Calculator"
              src="https://parxfit.com/emorya/?theme=dark"
              style={{
                width: "100%",
                height: window.innerWidth < 800 ? 900 : 750,
              }}
              frameBorder="0"
              scrolling="no"
              id="calcContainer"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
