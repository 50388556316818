import { useState, useEffect, useCallback } from "react";
import "../Landing.css";
export default function LandingPage() {
  const [y, setY] = useState(window.scrollY);
  const frameOne = window.innerHeight;
  var ww;
  var yPos;
  const [usercount, setUserCount] = useState("000000");
  const getUsers = () => {
    try {
      fetch("https://api.emorya.com/total-users")
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setUserCount(data.total_users.toString().padStart(9, "0"));
          //       setPhotos(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const targetDate = new Date("2024-09-15T23:00:00"); // Your target date
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        months: String(
          Math.floor(difference / (1000 * 60 * 60 * 24 * 30))
        ).padStart(2, "0"),
        days: String(
          Math.floor(
            (difference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
          )
        ).padStart(2, "0"),
        hours: String(
          Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        ).padStart(2, "0"),
        minutes: String(
          Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        ).padStart(2, "0"),
        seconds: String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(
          2,
          "0"
        ),
      };
    } else {
      timeLeft = {
        months: "00",
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > 0 && y <= frameOne) {
        // eslint-disable-next-line
        ww = (y / frameOne) * 100;
        ww = ww + 50;
        if (ww > 130) {
          ww = 130;
        }
        if (ww < 50) {
          ww = 50;
        }
        ww = ww * -1;
        document.getElementById("emrMonoContainer").style.marginLeft = ww + "%";
      }
      if (y > frameOne && y <= frameOne * 2) {
        // eslint-disable-next-line
        yPos = y - frameOne;
        ww = (yPos / frameOne) * 100;
        console.log(ww);
        ww = 120 - ww;
        document.getElementById("runnerContainer").style.left = ww + "%";
      }
      if (y > frameOne * 2 && y <= frameOne * 3) {
        yPos = y - frameOne;
        ww = (yPos / frameOne) * 100;
        ww = 100 - ww;
        document.getElementById("iconsContainer").style.left = ww + "%";
      }
      if (y > frameOne * 3 && y <= frameOne * 4) {
        yPos = y - frameOne * 3;
        ww = (yPos / frameOne) * 100;
        ww = 100 - ww;
        document.getElementById("code1").style.left = ww + "%";
      }
      if (y > frameOne * 4 && y <= frameOne * 5) {
        yPos = y - frameOne * 4;
        ww = (yPos / frameOne) * 100;
        ww = 100 - ww;
        document.getElementById("code2").style.left = ww + "%";
      }
      if (y > frameOne * 5 && y <= frameOne * 6) {
        yPos = y - frameOne * 5;
        ww = (yPos / frameOne) * 100;
        ww = 100 - ww;
        document.getElementById("code3").style.left = ww + "%";
      }
      setY(window.scrollY);
    },
    [y]
  );
  useEffect(() => {
    if (window.innerWidth > window.innerHeight) {
      window.addEventListener("scroll", handleNavigation);
    }
    return () => {
      if (window.innerWidth > window.innerHeight) {
        window.removeEventListener("scroll", handleNavigation);
      }
    };
  }, [handleNavigation]);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    getUsers();
    return () => clearInterval(timer); // Cleanup the interval on component unmount
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="section-line-container">
        <div className="section-line">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/logotop.png")}
              style={{
                maxWidth: window.innerWidth < window.innerHeight ? "30%" : 150,
                marginTop: 0,
              }}
            />
          </div>
          <img
            alt="Emorya"
            src={require("../assets/landing/QR.png")}
            className="qrBox showDesk"
            style={{
              maxWidth: "12%",
              marginBottom: 20,
            }}
          />
        </div>
      </div>
      <div className="oneDiv section">
        <div
          style={{
            cursor: "pointer",
            position: "absolute",
            top: window.innerWidth < window.innerHeight ? 45 : 60,
            right: window.innerWidth < window.innerHeight ? 30 : 60,
            zIndex: 3000,
            textAlign: "right",
          }}
        >
          <div id="google_translate_element"></div>
          <img
            onClick={() => {
              window.open("https://t.me/EmoryaFinanceInternational", "_blank");
            }}
            alt="Emorya"
            src={require("../assets/landing/telegram-white.png")}
            style={{
              height: window.innerWidth < window.innerHeight ? 36 : 44,
              marginRight: 20,
            }}
          />
          <img
            onClick={() => {
              window.open(
                "https://apps.apple.com/ro/app/emorya/id6449254736",
                "_blank"
              );
            }}
            alt="Emorya"
            src={require("../assets/landing/getapp.png")}
            style={{
              maxWidth: window.innerWidth < window.innerHeight ? "30%" : 150,
              marginTop: 0,
            }}
          />
        </div>
        <div className="backgroundItem ani_slideInLeft">
          <img
            alt="Emorya"
            src={require("../assets/landing/neon.png")}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div
          className="backgroundItem ani_slideInRight"
          style={{
            left: "auto",
            right: 0,
            alignItems: "flex-end",
            paddingTop: "20%",
          }}
        >
          <img
            alt="Emorya"
            src={require("../assets/landing/money.png")}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div className="arziContainer">
          <div className="hugeFont" style={{
            textTransform:'uppercase'
          }}>
          arzi calorii si faci bani
          </div>
          <img
            alt="Emorya"
            className="arzi"
            src={require("../assets/landing/arzi.png")}
            style={{display:'none'}}
          />
          <br />
          <br />
          <span className="subFont">
            Arde calorii și primește recompense, simplu și ușor, chiar de pe
            telefonul tău.
          </span>
          <br />
          <br />
          <img
            onClick={() => {
              window.open(
                "https://apps.apple.com/ro/app/emorya/id6449254736",
                "_blank"
              );
            }}
            alt="Emorya"
            src={require("../assets/landing/apple-black.png")}
            style={{
              width: 200,
              margin: 20,
              cursor: "pointer",
            }}
          />
          <img
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.emorya.emorya_sport_app_flutter",
                "_blank"
              );
            }}
            alt="Emorya"
            src={require("../assets/landing/google-play-black.png")}
            style={{
              width: 200,
              margin: 20,
              cursor: "pointer",
            }}
          />
          <br />
          Folosește acum codul de refferal Emorya și începe sa câștigi!
          <br />
          <div
            onClick={() => {
              navigator.clipboard.writeText("E5RsvIkawxl3kTpT");
              alert("Code Copied!");
            }}
            className="coupon"
          >
            <div
              style={{
                float: "right",
              }}
            >
              <i className="far fa-copy"></i>
            </div>
            <strong
              style={{
                fontSize: 15,
              }}
            >
              E5RsvIkawxl3kTpT
            </strong>
          </div>
        </div>
        <div
          id="emrMonoContainer"
          className="showDesk"
          style={{
            fontWeight: "bold",
            position: "absolute",
            width: "200%",
            bottom: 20,
            textAlign: "right",
            marginLeft: "-50%",
            opacity: 0.2,
          }}
        >
          <img
            alt="Emorya"
            className="emrMono"
            id="mobileOne"
            src={require("../assets/landing/mono.png")}
            style={{}}
          />
          EMORYA YOUR HEALTH
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div className="col-sm-12 hugeFontSemi">
            cum funcționează?
            <div
              className="subFont showMobile"
              style={{
                fontWeight: "normal",
                fontSize: 12,
                marginTop: 10,
                borderBottom: 5,
                borderBottomColor: "#BC4FFF",
                borderBottomStyle: "solid",
              }}
            >
              Nu e nevoie să fii expert în tehnologie sau să știi cum
              funcționează criptomonedele. Aplicația face totul pentru tine.
              <br />
              <br />
            </div>
          </div>
          <div
            className="col-sm-6"
            style={{
              position: "relative",
            }}
          >
            <img
              alt="Emorya"
              className="iphone"
              src={require("../assets/landing/iphone.png")}
            />
          </div>
          <div
            className="col-sm-6"
            style={{
              textAlign:
                window.innerWidth < window.innerHeight ? "center" : "right",
            }}
          >
            <div
              className="subFont showDesk"
              style={{
                fontWeight: "normal",
                fontSize: 30,
                marginTop: 100,
              }}
            >
              Nu e nevoie să fii expert în tehnologie sau să știi cum
              funcționează criptomonedele. Aplicația face totul pentru tine.
              <span className="showDesk">
                <br />
              </span>
            </div>
            <div className="subFont descarca">
              <span className="showDesk">
                <br />
                <br />
              </span>
              Descarcă aplicația noastră de pe
            </div>
            <br />
            <br />
            <img
              onClick={() => {
                window.open(
                  "https://apps.apple.com/ro/app/emorya/id6449254736",
                  "_blank"
                );
              }}
              alt="Emorya"
              src={require("../assets/landing/apple-orange.png")}
              style={{
                width: 200,
                marginRight: window.innerWidth < window.innerHeight ? 0 : 20,
                cursor: "pointer",
              }}
            />
            <img
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.emorya.emorya_sport_app_flutter",
                  "_blank"
                );
              }}
              alt="Emorya"
              src={require("../assets/landing/google-orange.png")}
              style={{
                width: 200,
                marginTop: window.innerWidth < window.innerHeight ? 20 : 0,
                cursor: "pointer",
              }}
            />
            <br />
            <br />
            Utilizați codul de recomandare <strong>
              E5RsvIkawxl3kTpT
            </strong>{" "}
            pentru a intra în aplicație.
          </div>
        </div>
      </div>
      <div className="oneDiv section" style={{}}>
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div className="col-sm-12 hugeFontSemi showMobile">
            Conectează-te și începe să arzi calorii
            <div
              className="subFont"
              style={{
                fontWeight: "normal",
                fontSize: 12,
                marginTop: 10,
              }}
            >
              Fie că mergi la sală, alergi în parc sau faci exerciții acasă, noi
              contorizăm efortul tău.
              <br />
              <br />
            </div>
          </div>
          <div
            className="col-sm-6 showDesk"
            style={{
              textAlign: "left",
            }}
          >
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
              }}
            >
              Conectează-te și începe să arzi calorii
            </div>
            <div className="subFont">
              Fie că mergi la sală, alergi în parc sau faci exerciții acasă, noi
              contorizăm efortul tău.
            </div>
            <br />
          </div>
          <div
            className="col-sm-6"
            style={{
              position: "relative",
            }}
          >
            <div
              id="runnerContainer"
              className="showDesk"
              style={{
                fontWeight: "bold",
                position: "absolute",
                left: "120%",
                top: 0,
              }}
            >
              <img
                alt="Emorya"
                className="showDesk"
                src={require("../assets/landing/running.png")}
                style={{
                  height: "70vh",
                }}
              />
            </div>
            <img
              className="showDesk"
              alt="Emorya"
              src={require("../assets/landing/track-new.png")}
              style={{
                maxWidth: "100%",
              }}
            />
            <img
              className="showMobile"
              alt="Emorya"
              src={require("../assets/landing/runnermobile.png")}
              style={{
                width: "110%",
                marginTop: 30,
              }}
            />
          </div>
        </div>
        <div
          id="iconsContainer"
          className="iconsContainer showDesk"
          style={{}}
        ></div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-5 hugeFont showDesk"
            style={{
              textAlign: "left",
              textTransform: "uppercase",
            }}
          >
            De ce să
          </div>
          <div
            className="col-sm-4"
            style={{
              textAlign: "left",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/goals.png")}
              style={{
                maxWidth:
                  window.innerWidth < window.innerHeight ? "100%" : "70%",
                marginTop: 30,
              }}
            />
            <div
              className="subFont"
              style={{
                textAlign: "left",
                marginTop: 10,
              }}
            >
              {usercount}+ Users using app.
            </div>
          </div>
          <div
            className="col-sm-12 hugeFont"
            style={{
              textAlign: "left",
              textTransform: "uppercase",
            }}
          >
            <span className="showMobile">
              <br />
              De ce să
            </span>
            alegi EMORYA?
          </div>
          <div
            className="col-sm-12 subFont"
            style={{
              textAlign: "center",
              fontSize: window.innerWidth < window.innerHeight ? 15 : 35,
              fontWeight: "normal",
            }}
          >
            De ce să alegi aplicația noastră?
          </div>
          <div
            className="col-sm-12 subFont"
            style={{
              textAlign: "center",
              fontWeight: "normal",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 50,
              fontSize: window.innerWidth < window.innerHeight ? 13 : 25,
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/star.png")}
              style={{
                height: 30,
              }}
            />
            &nbsp;&nbsp; 1852+ 5 Star App Review
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-4"
            style={{
              textAlign:
                window.innerWidth < window.innerHeight ? "center" : "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="hugeFontSemi showMobile">Ușor de utilizat</div>
            <div className="mediumFont showDesk">Ușor de utilizat</div>
            <div className="subFont">
              <span className="showMobile">
                <br />
              </span>
              Nu ai nevoie de cunoștințe tehnice. Tot ce trebuie să faci este să
              te miști și să arzi calorii. Noi ne ocupăm de restul.
              <span className="showMobile">
                <br />
              </span>
            </div>
          </div>
          <div className="col-sm-4">
            <img
              alt="Emorya"
              src={require("../assets/landing/mobile1.png")}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
          <div
            className="col-sm-4 midCol"
            style={{
              borderWidth: 0,
              position: "relative",
            }}
          >
            <img
              id="code1"
              className="showDesk"
              alt="Emorya"
              src={require("../assets/landing/code1.png")}
              style={{
                maxWidth: "100%",
                position: "absolute",
                top: "20%",
                left: "120%",
              }}
            />
            <img
              className="showMobile"
              alt="Emorya"
              src={require("../assets/landing/code1.png")}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-4 midCol"
            style={{
              borderWidth: 0,
            }}
          >
            <img
              id="code2"
              className="showDesk"
              alt="Emorya"
              src={require("../assets/landing/code2.png")}
              style={{
                maxWidth: "100%",
                position: "absolute",
                top: "20%",
                left: "-120%",
              }}
            />
          </div>
          <div
            className="col-sm-4 showMobile"
            style={{
              textAlign: "center",
            }}
          >
            <div
              className="mediumFont"
              style={{
                textAlign: "center",
              }}
            >
              Recompense reale
            </div>
            <br />
            <div className="subFont">
              Caloriile tale arse se transformă în bani. Este modalitatea
              perfectă de a rămâne motivat!
            </div>
            <br />
            <br />
          </div>
          <div className="col-sm-4">
            <img
              alt="Emorya"
              src={require("../assets/landing/mobile1.png")}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
          <div
            className="col-sm-4 showDesk"
            style={{
              textAlign: "left",
              display: window.innerWidth < window.innerHeight ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="mediumFont">Recompense reale</div>
            <div className="subFont">
              Caloriile tale arse se transformă în bani. Este modalitatea
              perfectă de a rămâne motivat!
            </div>
          </div>
          <div className="col-sm-4 showMobile">
            <img
              className="showMobile"
              alt="Emorya"
              src={require("../assets/landing/code2.png")}
              style={{
                width: "100%",
                marginTop: 50,
                marginBottom: 50,
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-8"
            style={{
              textAlign:
                window.innerWidth < window.innerHeight ? "left" : "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div className="mediumFont">Întrebări frecvente</div>
            <span className="showMobile">
              <br />
              <br />
            </span>
            <div
              style={{
                fontSize: 15,
              }}
            >
              <ul
                style={{
                  listStyle: "decimal",
                  padding: 0,
                  paddingLeft: window.innerWidth < window.innerHeight ? 0 : 40,
                }}
              >
                <li>
                  <strong>Nu știu cum să cumpăr EMORYA.</strong>
                  <br />
                  Mai jos ai un tutorial simplu care îți explica cum poți
                  cumpara:
                  <br />
                  https://docs.emorya.com/docs/howtos/xportal
                </li>
                <li
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <strong>De unde vin banii pentru caloriile arse?</strong>
                  <br />
                  Banii vin prin utilitatea monedei de baza și a ecosistemului
                  nostru hiperdeflationar care o data cu fiecare tranzactie
                  devine tot mai rar și mai valoros. Aplicatia va genera mai
                  multe surse de venit, proiectul își dorește sa reinvestească
                  50% in mod constant pentru a crea cerere și raritate continua.
                </li>
                <li
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <strong>Cât costă aplicația?</strong>
                  <br />
                  Vei avea acces la funcționalitățile de bază și vei putea
                  câștiga recompense doar prin arderea caloriilor.
                </li>
                <li
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <strong>
                    In cât timp pot genera bani dacă voi descarca aplicatia?
                  </strong>
                  <br />
                  Poți genera bani chiar din primele ore de la descărcarea
                  aplicatiei. Trebuie doar sa ai o suma minima de EMR(1-10 EMR)
                  pe care ii depui in stakingul dinamic, apoi poți sa arzi
                  calorii și sa începi sa monetizezi chiar din primele ore,
                  datorită modelului economic special conceput pentru a
                  revolutiona economia.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-sm-4"
            style={{
              display: "none",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/mobile1.png")}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
          <div
            className="col-sm-4 midCol"
            style={{
              borderWidth: 0,
            }}
          >
            <img
              id="code3"
              className="showDesk"
              alt="Emorya"
              src={require("../assets/landing/code3.png")}
              style={{
                maxWidth: "100%",
                position: "absolute",
                top: "20%",
                left: "120%",
              }}
            />
            <img
              className="showMobile"
              alt="Emorya"
              src={require("../assets/landing/code3.png")}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="oneDiv section "
        style={{
          minHeight:
            window.innerWidth < window.innerHeight
              ? window.innerWidth
              : "100vh",
        }}
      >
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div className="col-sm-12 showDesk">
            <iframe
              style={{
                width: (window.innerHeight * 0.8 * 560) / 315,
                height: window.innerHeight * 0.8,
                borderWidth: 10,
                borderColor: "#fff",
                borderStyle: "solid",
                borderRadius: 5,
              }}
              src="https://www.youtube.com/embed/vFpmgP6YPPY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div
            className="col-sm-12 showMobile"
            style={{
              width: "100%",
              overflow: "hidden",
              padding: 0,
            }}
          >
            <iframe
              style={{
                height: (window.innerWidth * 0.9 * 315) / 560,
                width: window.innerWidth * 0.8,
                borderWidth: 2,
                borderColor: "#fff",
                borderStyle: "solid",
                borderRadius: 5,
              }}
              src="https://www.youtube.com/embed/vFpmgP6YPPY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-6 textCenter"
            style={{
              borderWidth: 0,
              textAlign: "left",
            }}
          >
            <div
              className="mediumFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 30 : 60,
              }}
            >
              De unde vin banii pentru caloriile arse?
            </div>
            <br />
            Banii vin prin utilitatea monedei de baza și a ecosistemului nostru 
            special creat, hiperdeflationar care o data cu fiecare tranzactie
            devine tot mai rar și mai valoros.
            <br />
            <br />
            Aplicatia va genera mai multe surse de venit, proiectul își dorește
            sa reinvestească 50% in mod constant pentru a crea cerere și
            raritate continua.
          </div>
          <div
            className="col-sm-6"
            style={{
              padding: 0,
              textAlign: "center",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/slide1.png")}
              style={{
                maxWidth: "100%",
                marginTop: window.innerWidth < window.innerHeight ? 40 : 0,
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-6 textCenter"
            style={{
              borderWidth: 0,
              textAlign: "left",
            }}
          >
            <div
              className="mediumFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 30 : 60,
              }}
            >
              Cât costă aplicația?
            </div>
            <br />
            Descărcarea aplicației este gratuită! 
            <br />
            <br />
            Vei avea acces la funcționalitățile de bază și vei putea câștiga
            recompense doar prin arderea caloriilor.
          </div>
          <div
            className="col-sm-6"
            style={{
              textAlign: "center",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/slide2.png")}
              style={{
                maxWidth: "100%",
                marginTop: window.innerWidth < window.innerHeight ? 40 : 0,
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-6 textCenter"
            style={{
              borderWidth: 0,
              textAlign: "left",
            }}
          >
            <div
              className="mediumFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 30 : 60,
              }}
            >
              În cât timp pot genera bani dacă descarc aplicatia?
            </div>
            <br />
            Poți genera bani chiar din primele ore de la descărcarea aplicatiei.
            Trebuie doar sa ai o suma minima de EMR (1-10 EMR) pe care ii depui
            in stakingul dinamic, apoi poți sa arzi calorii și sa începi sa
            monetizezi chiar din primele ore, datorită modelului economic
            special conceput pentru a revoluționa economia.
          </div>
          <div className="col-sm-6">
            <img
              alt="Emorya"
              src={require("../assets/landing/slide3.png")}
              style={{
                maxWidth: "100%",
                marginTop: window.innerWidth < window.innerHeight ? 40 : 0,
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-6"
            style={{
              textAlign: "left",
              borderBottomWidth: 1,
              borderBottomColor: "#fff",
              borderBottomStyle: "solid",
            }}
          >
            <div
              className="showMobile"
              style={{
                float: "right",
              }}
            >
              <img
                alt="Emorya"
                src={require("../assets/landing/avatar.png")}
                style={{
                  width: 100,
                }}
              />
            </div>
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
                fontSize: window.innerWidth < window.innerHeight ? 25 : 60,
              }}
            >
              recenziile
              <br />
              membrilor
            </div>
            <div
              className="subFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 12 : 30,
              }}
            >
              Ce spun membrii noștri despre aplicație?
              <br />
              <br />
            </div>
          </div>
          <div
            className="col-sm-6 showDesk"
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "#fff",
              borderBottomStyle: "solid",
              position: "relative",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/avatar.png")}
              style={{
                maxWidth: "60%",
                position: "absolute",
                left: 0,
                bottom: -40,
              }}
            />
          </div>
          <div
            className="col-sm-6"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/testi.png")}
              style={{
                maxWidth:
                  window.innerWidth < window.innerHeight ? "80%" : "30%",
              }}
            />
          </div>
          <div
            className="col-sm-6"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            <div
              className="subFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 12 : 22,
                fontWeight: "normal",
              }}
            >
              Eu cred ca este un proiect revoluționar! Ideea de a putea arde
              calorii si castiga bani la doar câteva click-uri distanța este
              extraordinara! In momentul in care aplicatia se va lansa și ajungi
              sa vezi ca efortul depus se transforma in bani, eu cred ca va fi
              ceva revoluționar!
            </div>
          </div>
        </div>
      </div>
      <div
        className="oneDiv section"
        style={{
          display: "none",
        }}
      >
        <div
          className="row"
          style={{
            width: "90%",
            minHeight: "90vh",
          }}
        >
          <div className="col-sm-12">
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
                fontSize: window.innerWidth < window.innerHeight ? 25 : 60,
                textAlign: "center",
                paddingBottom: 40,
              }}
            >
              Lansăm în
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="timerContainer">
              <div className="timer">{String(timeLeft.days).charAt(0)}</div>
              <div className="timer">{String(timeLeft.days).charAt(1)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Days
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="timerContainer">
              <div className="timer">{String(timeLeft.hours).charAt(0)}</div>
              <div className="timer">{String(timeLeft.hours).charAt(1)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Hours
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="timerContainer">
              <div className="timer">{String(timeLeft.minutes).charAt(0)}</div>
              <div className="timer">{String(timeLeft.minutes).charAt(1)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Minutes
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="timerContainer">
              <div className="timer">{String(timeLeft.seconds).charAt(0)}</div>
              <div className="timer">{String(timeLeft.seconds).charAt(1)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Seconds
            </div>
          </div>
          <div className="col-sm-12">
            <div
              className="mediumFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 15 : 30,
                textAlign: "center",
                marginTop: 50,
                fontWeight: "normal",
              }}
            >
              Fii printre primii care se bucură de recompensele oferite de
              aplicația noastră!
            </div>
          </div>
        </div>
      </div>
      <div
        className="oneDiv section"
        style={{
          minHeight: window.innerWidth < window.innerHeight ? "70vh" : "90vh",
        }}
      >
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div className="col-sm-12">
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
                fontSize: window.innerWidth < window.innerHeight ? 25 : 60,
                textAlign: "center",
                paddingBottom: 40,
              }}
            >
              Utilizatorii în timp real!
            </div>
          </div>
          <div className="col-sm-12 col-12">
            <div className="timerContainer userTimer">
              <div className="timer">{String(usercount).charAt(0)}</div>
              <div className="timer">{String(usercount).charAt(1)}</div>
              <div className="timer">{String(usercount).charAt(2)}</div>
              <div className="timer">{String(usercount).charAt(3)}</div>
              <div className="timer">{String(usercount).charAt(4)}</div>
              <div className="timer">{String(usercount).charAt(5)}</div>
              <div className="timer">{String(usercount).charAt(6)}</div>
              <div className="timer">{String(usercount).charAt(7)}</div>
              <div className="timer">{String(usercount).charAt(8)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
              }}
            >
              Users currently live on app.
            </div>
          </div>
        </div>
      </div>
      <div className="oneDiv section" style={{}}>
        <div
          className="row"
          style={{
            width: window.innerWidth < window.innerHeight ? "100%" : "90%",
          }}
        >
          <div className="col-sm-2"></div>
          <div
            className="col-sm-8"
            style={{
              textAlign: "center",
            }}
          >
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: window.innerWidth < window.innerHeight ? 30 : 60,
              }}
            >
              descarcă aplicația
              <br />
            </div>
            <div
              className="subFont"
              style={{
                fontWeight: "normal",
              }}
            ></div>
            <br />
            <img
              alt="Emorya"
              src={require("../assets/landing/challange1.png")}
              style={{
                maxWidth:
                  window.innerWidth < window.innerHeight ? "100%" : "80%",
              }}
            />
            <br />
            <br />
            <img
              onClick={() => {
                window.open(
                  "https://apps.apple.com/ro/app/emorya/id6449254736",
                  "_blank"
                );
              }}
              alt="Emorya"
              src={require("../assets/landing/apple-orange.png")}
              style={{
                width: 200,
                cursor: "pointer",
              }}
            />
            <img
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.emorya.emorya_sport_app_flutter",
                  "_blank"
                );
              }}
              alt="Emorya"
              src={require("../assets/landing/google-orange.png")}
              style={{
                width: 200,
                marginLeft: window.innerWidth < window.innerHeight ? 0 : 20,
                marginTop: window.innerWidth < window.innerHeight ? 20 : 0,
                cursor: "pointer",
              }}
            />
            <br />
            <br />
            <div id="google_translate_element"></div>
            Utilizați codul de recomandare <strong>
              E5RsvIkawxl3kTpT
            </strong>{" "}
            pentru a intra în aplicație.
            <div
              style={{
                width: 350,
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://t.me/EmoryaFinanceInternational",
                  "_blank"
                );
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
