import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import legalPDF from '../../assets/documents/terms-of-use.pdf';


export default function Legal() {


    const navigate = useNavigate();

    useEffect(() => {
        window.open(legalPDF, '_blank');

        navigate('/');
    }, [navigate]);

}