import "../App.css";
export default function ReachOut() {
  return (
    <>
      <div className="section">
        <div className="row row90 headerReachOut">
          <div className="col-sm-12">
            <div className="whiteHeading reachOut">
              Any question?
              <br />
              Reach out to us, we are hyperactive!
            </div>
          </div>
          <div
            className="col-xs-6 col-sm-6 col-md-6 col-lg-8 orderMobile"
            style={{
              padding: 0,
            }}
          >
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 reactBox">
                <div className="reachSmallBox">
                  <div
                    className="btn-green-wrapper"
                    style={{
                      borderRadius: "100%",
                    }}
                  >
                    <div
                      className="btn-green"
                      style={{
                        borderRadius: "100%",
                      }}
                    >
                      <i className="fas fa-envelope"></i>
                    </div>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;support@emorya.com
                </div>

                <div className="reachSmallBox">
                  <div
                    className="btn-green-wrapper"
                    style={{
                      borderRadius: "100%",
                    }}
                  >
                    <div
                      className="btn-green"
                      style={{
                        borderRadius: "100%",
                      }}
                    >
                      <i className="fas fa-paper-plane"></i>
                    </div>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;Join us on Telegram (
                  <a
                    href="https://t.me/EmoryaFinanceInternational"
                    className="green-color"
                  >
                    INT
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://t.me/EmoryaFinanceRO"
                    className="green-color"
                  >
                    RO
                  </a>
                  )
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 reactBox">
                <div className="reachSmallBox">
                  <div
                    className="btn-green-wrapper"
                    style={{
                      borderRadius: "100%",
                    }}
                  >
                    <div
                      className="btn-green"
                      style={{
                        borderRadius: "100%",
                      }}
                    >
                      <i className="fab fa-twitter"></i>
                    </div>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;Follow us on{" "}
                  <a
                    href="https://twitter.com/EmoryaFinance"
                    className="green-color"
                  >
                    Twitter
                  </a>
                </div>

                <div className="reachSmallBox">
                  <div
                    className="btn-green-wrapper"
                    style={{
                      borderRadius: "100%",
                    }}
                  >
                    <div
                      className="btn-green"
                      style={{
                        borderRadius: "100%",
                      }}
                    >
                      <i className="fas fa-tree"></i>
                    </div>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href="https://linktr.ee/emoryafinance"
                    className="green-color"
                  >
                    Linktree
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 robot">
            <img
              alt="Emorya"
              src={require("../assets/robot.png")}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
