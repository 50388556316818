import "../App.css";
import Footer from "./Footer";
import Header from "./Header";

export default function Contract() {
  return (
    <>
      <Header homepage={false}></Header>

      <div
        className="headRectangle privacyHead"
        style={{
          minHeight: 3800,
          // marginTop: 600,
        }}
      >
        <div className="row">
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            style={{
              paddingTop: 50,
            }}
          >
            <div className="green-heading">
              <strong>Privacy Policy</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                Emorya IT SRL built the Emorya Sports app as a free app. This
                service is provided by Emorya IT SRL at no cost and is intended
                for use as is.
                <br />
                <br />
                This page informs visitors regarding our policies with the
                collection, use, and disclosure of Personal Information for
                anyone deciding to use our service.
                <br />
                <br />
                By choosing to use our service, you agree to the collection and
                use of information in relation to this policy. The Personal
                Information we collect is used for providing and improving the
                service. We will not use or share your information with anyone
                except as described in this Privacy Policy.
                <br />
                <br />
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, which are accessible at Emorya
                Sports unless otherwise defined in this Privacy Policy.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Information Collection and Use</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                For a better experience while using our service, we may require
                you to provide us with certain personally identifiable
                information. The information we request will be retained by us
                and used as described in this privacy policy.
                <br />
                <br />
                The app does use third-party services that may collect
                information used to identify you.
              </span>
              <br />
              <br />
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Third-party service providers used by the app include:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                <br />
                <ul>
                  <li>Google Play Services</li>
                  <li>Google Analytics for Firebase</li>
                  <li>Firebase Crashlytics</li>
                  <li>Google Data</li>
                </ul>
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                We may collect and process the following Google Data as part of
                our service:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                <br />
                <ul>
                  <li>
                    Google account information for user authentication and
                    personalized services.
                  </li>
                  <li>
                    Google Analytics for usage data to improve our service.
                  </li>
                  <li>
                    Firebase Crashlytics for crash reports and diagnostic
                    information.
                  </li>
                </ul>
                Note that any data collected by Google is subject to Google's
                Privacy Policy.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Log Data</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                In the event of an error in the app, we collect data and
                information on your phone through third-party products. This Log
                Data may include your device's IP address, device name,
                operating system version, app configuration, time and date of
                use, and other statistics.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Cookies</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                Cookies are files with a small amount of data used as anonymous
                unique identifiers. These are sent to your browser from the
                websites you visit and are stored on your device's internal
                memory.
                <br />
                Our Service does not use these "cookies" explicitly. However,
                the app may use third-party code and libraries that use
                "cookies" to improve their services. You have the option to
                accept or refuse these cookies and be aware of when a cookie is
                being sent to your device.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Service Providers</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                We may employ third-party companies and individuals for the
                following reasons:
                <br />
                <br />
                <ul>
                  <li>To facilitate our service</li>
                  <li>To provide the service on our behalf</li>
                  <li>To perform service-related services</li>
                  <li>To assist us in analyzing how our service is used</li>
                </ul>
                These third parties have access to your Personal Information to
                perform tasks on our behalf and are obligated not to disclose or
                use the information for any other purpose.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Security</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                We value your trust in providing us your Personal Information
                and strive to use commercially acceptable means to protect it.
                However, no method of transmission over the internet or
                electronic storage is 100% secure and reliable.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Links to Other Sites</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                Our service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. We advise
                you to review the Privacy Policy of these websites as we do not
                control them.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Children's Privacy</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                Our services do not address anyone under the age of 13. We do
                not knowingly collect personal information from children under
                13. If we become aware that a child under 13 has provided us
                with personal information, we take steps to remove that
                information from our servers.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Changes to This Privacy Policy</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                We may update our Privacy Policy periodically. You are advised
                to review this page for any changes.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>User Data Policy</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Information Collected:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                <br />
                <ul>
                  <li>
                    Personal information such as name, email address, phone
                    number, billing information.
                  </li>
                  <li>
                    Non-personal information such as IP addresses, browser type,
                    and operating system.
                  </li>
                </ul>
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Use of Information:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                <br />
                <ul>
                  <li>To provide and improve our services</li>
                  <li>To communicate with users</li>
                  <li>To personalize user experience</li>
                  <li>For research and analysis</li>
                  <li>To comply with legal and regulatory requirements</li>
                </ul>
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Data Sharing:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                We do not sell, trade, or rent user information to third parties
                for marketing purposes. We may share information with trusted
                third-party service providers for business operations, ensuring
                confidentiality.
              </span>
              <br />
              <br />
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Security Measures:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                We implement security measures to protect user information and
                use encryption technology for transmitting sensitive data.
              </span>
              <br />
              <br />
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                User Rights:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                Users have the right to access, correct, delete their personal
                information, and opt-out of promotional emails.
              </span>
              <br />
              <br />
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Children's Privacy:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                Our website is not intended for children under 13, and we do not
                collect their personal information.
              </span>
              <br />
              <br />
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Changes to User Data Policy:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                We reserve the right to modify this User Data Policy. Changes
                will be communicated via email or our website.
              </span>
              <br />
              <br />
            </div>

            <div className="green-subheading">
              <strong>Health Data Policy</strong>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Types of Health Data Collected:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                <br />
                <ul>
                  <li>
                    Medical records, lab results, diagnostic imaging, and
                    insurance information.
                  </li>
                </ul>
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Collection and Use of Health Data:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                <br />
                <ul>
                  <li>For providing healthcare services</li>
                  <li>For processing insurance claims</li>
                  <li>For research and analysis</li>
                  <li>Compliance with legal requirements</li>
                </ul>
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Security Measures:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                We take security measures for protecting health data, including
                physical and technical security measures like encryption.
                <br />
                <br />
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Access and Control:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                Patients have the right to access, review, and control their
                health data.
                <br />
                <br />
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Compliance:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                We comply with laws like HIPAA and conduct regular risk
                assessments.
                <br />
                <br />
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Data Retention:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                Health data is retained as required by law or necessary for
                services.
                <br />
                <br />
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Sharing of Health Data:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                We share health data with third parties for healthcare services
                or insurance claims processing, ensuring confidentiality.
                <br />
                <br />
              </span>
            </div>

            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Changes to Health Data Policy:
              </span>
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                <br />
                We may modify this Health Data Policy, with changes communicated
                via email or our website.
                <br />
                <br />
              </span>
            </div>

            <div className="green-subheading">
              <strong>Contact Information</strong>
            </div>
            <div className="text">
              <span style={{ fontSize: 16, fontWeight: "normal" }}>
                For questions or concerns regarding our policies, please contact
                us at [support@emorya.com].
                <br />
                <br />
                Note: This document includes limited use disclosures for both
                the User Data Policy and Health Data Policy, ensuring
                confidentiality and specific use of personal information.
              </span>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
