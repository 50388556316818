import { useEffect, useState } from "react";

export default function Modal({ teamInnerData, unSetTeamData }) {

    const [teamData, setTeamData] = useState(teamInnerData);

    useEffect(() => {
        setTeamData(teamInnerData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>{
            (teamData.length !== 0) ? <>
                <div className="modalContainer" style={{
                    display: (teamData.length !== 0) ? 'flex' : 'none'
                }}>

                    <div className="modalBox">
                        <div className="closeButton" onClick={() => {
                            unSetTeamData();
                        }}>
                            <i className="fas fa-times"></i>

                        </div>
                        <div className="row">

                            <div className="col-sm-12" style={{
                                color: '#222'
                            }}>
                                <div className="row">
                                    <div className="col-sm-3 modalImage" style={{
                                        padding: 0
                                    }}>
                                        <img alt="Emorya" src={require("../assets/team/" + teamData.image)}  />
                                    </div>
                                    <div className="col-sm-8 mDataContainer" style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-start',
                                        
                                    }}>
                                        <div className="mName">{teamData.name}</div>
                                        <div className="mTitle">{teamData.title}</div>



                                        <div className="mSocial">
                                            {
                                                (teamData.facebook && teamData.facebook !== '') ?
                                                    <>
                                                        <a href={teamData.facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook"></i></a>&nbsp;&nbsp;
                                                    </> :
                                                    <>
                                                    </>
                                            }

                                            {
                                                (teamData.twitter && teamData.twitter !== '') ?
                                                    <>
                                                        <a href={teamData.twitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>&nbsp;&nbsp;
                                                    </> :
                                                    <>
                                                    </>
                                            }


                                            {
                                                (teamData.instagram && teamData.instagram !== '') ?
                                                    <>
                                                        <a href={teamData.instagram} target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a>&nbsp;&nbsp;
                                                    </> :
                                                    <>
                                                    </>
                                            }


                                            {
                                                (teamData.linkedin && teamData.linkedin !== '') ?
                                                    <>
                                                        <a href={teamData.linkedin} target="_blank" rel="noreferrer"><i className="fab fa-linkedin"></i></a>

                                                    </> :
                                                    <>
                                                    </>
                                            }





                                        </div>
                                    </div>



                                </div>
                                <div className="mAbout">
                                    {
                                        teamData.about.split("<br/>").map(line => {
                                            return (
                                                <p>{line}</p>
                                            )

                                        })

                                    }</div>

                                {
                                    teamData.skills.map(skill => {
                                        return (
                                            <>
                                                <div className="skillName">
                                                    <div className="skillPercent">{skill.percent}</div>
                                                    {skill.name}</div>
                                                <div className="skillLine">
                                                    <div className="skillWidth" style={{
                                                        width:(skill.percent)
                                                    }}></div>
                                                </div>


                                            </>
                                        )
                                    })
                                }


                            </div>


                        </div>
                    </div>
                </div>

            </> : <>

            </>
        }

        </>
    )
}