import "../App.css";
import Canvas from "./Canvas";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/logo.svg";

export default function Footer() {
  return (
    <>
      <div
        className="section footer"
        style={{
          marginTop: 80,
        }}
      >
        <Canvas divId="footerStars"></Canvas>
        <div className="row row90 teamContainer justify-content-center">
          <div className="col-4 col-lg-12 d-flex justify-content-center d-lg-block">
            <img alt="logo" src={logo} className="logoMenu" />
            <br />
            <br />
          </div>
          <div className="col-lg-3">
            <div className="lightHeading text-center text-lg-left">
              EMORYA FZC
            </div>
            <div className="text text-center text-lg-left">
              Sharjah Research Technology and Innovation Park
              <br />
              P.O. Box 66636 Sharjah
              <br />
              Block B, Office - B14 - 130
            </div>
            <div className="lightHeading text-center text-lg-left">
              EMORYA IT S.R.L.
            </div>
            <div className="text text-center text-lg-left">
              Strada LXII, Nr. 34A
              <br />
              Sat Sag, Comuna Sag, Judet Timis 
              <br />
              CUI: 47538686
            </div>
          </div>
          <div className="col-lg-3">
            <div className="lightHeading text-center text-lg-left">
              Resources
            </div>
            <div className="text text-center text-lg-left">
              <HashLink to="/">Home</HashLink>
              <HashLink to="/#token-sale">Token Sale</HashLink>
              <HashLink to="/#team-section">Team</HashLink>
              <HashLink to="/#road-map">Road Map</HashLink>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="lightHeading text-center text-lg-left">Company</div>
            <div className="text text-center text-lg-left">
              <a href="https://emorya.com/#/partner/">Become Partner</a>
              <a
                href="https://docs.emorya.com/docs/nft/introduction/"
                target="_blank"
                rel="noreferrer"
              >
                NFT Whitepaper
              </a>
              <a href="/#/terms-of-use">Terms of Use</a>
              <a href="/#/token-release">Token Release</a>
              <a
                href="https://docs.emorya.com/docs/whitepaper/introduction/"
                target="_blank"
                rel="noreferrer"
              >
                Whitepaper
              </a>

              <a
                href="https://emorya.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="lightHeading text-center text-lg-left mb-3">
              Trade on
            </div>
            <a
              href="https://xexchange.com/"
              target="_blank"
              rel="noreferrer"
              className="col-5 m-auto m-lg-0 col-lg-12 p-lg-0 d-flex justify-content-center d-lg-block"
            >
              <img alt="P2B" src={require("../assets/exchanges/exchanges.png")}  className="footer-trade mb-2"/>
            </a>
            <a
              href="https://www.mexc.com/"
              target="_blank"
              rel="noreferrer"
              className="col-5 m-auto col-lg-12 p-lg-0 d-flex justify-content-center d-lg-block"
            >
              <img alt="MEXC" src={require("../assets/exchanges/mexc.png")} className="footer-trade mb-2" />
            </a>
            
            <a
              href="https://www.bitmart.com/trade/en-US?symbol=EMR_USDT"
              target="_blank"
              rel="noreferrer"
              className="col-5 m-auto m-lg-0 col-lg-12 p-lg-0 d-flex justify-content-center d-lg-block"
            >
              <img alt="P2B" src={require("../assets/exchanges/bitmart.png")}  className="footer-trade mb-4"/>
            </a>
          </div>
        </div>
        <div className="footerBottom">
          <div className="row row90">
            <div className="col-sm-6">
              <div className="green-color footer-copy">
                Copyright 2024 | Emorya Finance
                <br />
                <span
                  style={{
                    opacity: 0.5,
                  }}
                >
                  All trademarks and copyrights belong to their respective
                  owners.
                </span>
              </div>
            </div>
            <div
              className="col-sm-6 footerIcons d-flex d-lg-block justify-content-center"
              style={{
                textAlign: "right",
              }}
            >
              <a
                href="https://www.facebook.com/emoryafinance"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Emorya"
                  src={require("../assets/footerfacebook.png")}
                />
              </a>
              <a
                href="https://twitter.com/EmoryaFinance"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Emorya"
                  src={require("../assets/footertwitter.png")}
                />
              </a>
              <a
                href="https://github.com/emorya-finance"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Emorya"
                  src={require("../assets/footerdiscord.png")}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
