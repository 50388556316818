import { useState, useEffect, useRef, useMemo } from "react";
import "../App.css";
import sportsImg from "../assets/sports.svg";
import sportsMobileImg from "../assets/sports-mobile.svg";
import { sports } from "../utils/constant";

export default function Sports() {
  const sectionId = "sportsSection";
  const sectionRef = useRef(null);
  const isInViewport1 = useIsInViewport(sectionRef);
  if (isInViewport1) {
    document.getElementById(sectionId).className =
      "section animate__animated animate__fadeInUp";
  }
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );
    useEffect(() => {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
    return isIntersecting;
  }

  return (
    <>
      <div id={sectionId} className="section" ref={sectionRef}>
        <div className="row row90">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3"></div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 sports">
            <div className="sportText">
              <div className="blueSubHeading">What is $EMRS</div>
              <div className="whiteHeading">EmoryaSportsX</div>
              <div className="whiteHeadingSmall">
                <br />
                Earn crypto by burning calories, and live the life you always
                wanted!
                <br />
                <br />
              </div>
              <div className="text">
                The idea behind{" "}
                <span className="green-color">EmoryaSportsX</span> is for you to
                earn rewards just by doing your day-to-day activities. Every
                step is counted, but of course, we could incentivize more the
                people who adopt a healthy life, do actual sports activities and
                successfully finish our daily challenges.
                <br />
                <br />
                <span className="green-color">
                  Coming Soon! The mining program is planned to become active in
                  Q4 2024
                </span>
              </div>
            </div>
          </div>
          {/* For desktop and tablet  */}
          <div className="position-relative m-auto sportsContainerBoxs">
            <img src={sportsImg} alt="sports img" className="w-100" />
            <div className="d-flex w-100 h-100 position-absolute sportsContent justify-content-center">
              {sports.map(({ id, title, text }) => {
                return (
                  <div
                    key={id}
                    className="d-flex flex-column align-items-center"
                  >
                    <span className="gradColor">{title}</span>
                    <span className="bodyText">{text}</span>
                  </div>
                );
              })}
            </div>
          </div>
          {/* For mobile */}
          <div className="position-relative m-auto sportsMobile">
            <img src={sportsMobileImg} alt="sports img" className="w-100" />
            <div className="d-flex flex-column w-100 h-100 position-absolute sportsContent sports-mobile justify-content-center">
              {sports.map(({ id, title, text }) => {
                return (
                  <div
                    key={id}
                    className="d-flex flex-column align-items-center"
                  >
                    <span className="gradColor">{title}</span>
                    <span className="bodyText">{text}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-sm-12 whitePaperBox">
            <div className="btn-green-wrapper">
              <button
                className="btn-green"
                onClick={() => {
                  window.open(
                    "https://docs.emorya.com/docs/whitepaper/introduction/",
                    "_blank"
                  );
                }}
              >
                Read Whitepaper
              </button>
            </div>
            <br />

            <span
              className="telegram gradColor"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://t.me/+-ua9M1WveQQ4NmRk", "_blank");
              }}
            >
              <i
                className="fas fa-paper-plane"
                style={{
                  fontSize: 15,
                }}
              ></i>
              &nbsp;&nbsp;Join Our Telegram
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
