import { useEffect, useState, useRef } from "react";
import "../App.css";
import "../styles/Partners.css";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import background from "../assets/partners/background.png"

export default function Partners() {
  const partnersSwiperRef = useRef(null);

  const [responsiveSettings, setResponsiveSettings] = useState({
    slidesPerView: 6, // default value for larger screens
    spaceBetween: 20, // adjust as needed
  });

  const [partners] = useState([
    {
      imageColor: "ro_frb.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.frbaschet.ro/",
      isHovered: false,
    },
    {
      imageColor: "bucuresti_dinamo.png",
      imageGray: "ellipse_simple.png",
      url: "https://dinamo1948.ro/",
      isHovered: false,
    },
    {
      imageColor: "bucuresti_victory_cup.png",
      imageGray: "ellipse_simple.png",
      url: "https://victorycup.ro/",
      isHovered: false,
    },
    {
      imageColor: "uk_domocos_fitness.png",
      imageGray: "ellipse_simple.png",
      url: "#",
      isHovered: false,
    },
    {
      imageColor: "chiajna_concordia.png",
      imageGray: "ellipse_simple.png",
      url: "https://csconcordia.ro/",
      isHovered: false,
    },
    {
      imageColor: "barsov_kids_tampa.png",
      imageGray: "ellipse_simple.png",
      url: "https://acstampabrasov.ro/",
      isHovered: false,
    },
    {
      imageColor: "voluntari_football_club.png",
      imageGray: "ellipse_simple.png",
      url: "https://fcvoluntari.ro/",
      isHovered: false,
    },
    {
      imageColor: "brasov_grand_fitness.png",
      imageGray: "ellipse_simple.png",
      url: "https://grand-fitness.ro/",
      isHovered: false,
    },
    {
      imageColor: "valcea_fairplay.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.facebook.com/FairPlayValcea/?locale=ro_RO",
      isHovered: false,
    },
    {
      imageColor: "bucuresti_fit_goo.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.facebook.com/FITandGooMilitariPTstudio/",
      isHovered: false,
    },
    {
      imageColor: "ploiesti_daniel_chirita.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.facebook.com/people/Scoala-de-Fotbal-Daniel-Chirita-Ploiesti-Grupa-2006/100063082786422/",
      isHovered: false,
    },
    {
      imageColor: "brasov_padel_palace.png",
      imageGray: "ellipse_simple.png",
      url: "https://padelpalace-ro.matchpoint.com.es/#1",
      isHovered: false,
    },
    {
      imageColor: "sighet_csm.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.csmsighet.ro/",
      isHovered: false,
    },
    {
      imageColor: "brasov_coltea.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.cscoltea.ro/",
      isHovered: false,
    },
    {
      imageColor: "dobresti_unirea.png",
      imageGray: "ellipse_simple.png",
      url: "https://acsunireadobroesti.ro/",
      isHovered: false,
    },
    {
      imageColor: "sighet_club_sportiv_scolar.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.css-sighet.ro/",
      isHovered: false,
    },
    {
      imageColor: "cluj_viitorul.png",
      imageGray: "ellipse_simple.png",
      url: "http://www.viitorulcluj.ro/",
      isHovered: false,
    },
    {
      imageColor: "stefanesti_club_sportiv.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.facebook.com/CSLStefanesti/",
      isHovered: false,
    },
    {
      imageColor: "uk_romania_fc.png",
      imageGray: "ellipse_simple.png",
      url: "https://fcromania.com/",
      isHovered: false,
    },
    {
      imageColor: "voluntari_basketball_club.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.csovoluntari.ro/",
      isHovered: false,
    },
    {
      imageColor: "constanta_sharks_kickbox.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.facebook.com/p/Sharks-Fit-Studio-by-Alex-Filip-100077110433165/",
      isHovered: false,
    },
    {
      imageColor: "circuit_works.png",
      imageGray: "ellipse_simple.png",
      url: "https://www.circuitworksla.com/",
      isHovered: false,
    },
  ]);

  useEffect(() => {
    const updateSwiperSettings = () => {
      const screenWidth = window.innerWidth;
      setResponsiveSettings((prevSettings) => {
        let newSettings = { ...prevSettings };

        if (screenWidth <= 640) {
          // for mobile devices
          newSettings.slidesPerView = 1;
          newSettings.spaceBetween = 10;
        } else if (screenWidth <= 870) {
          // for tablets
          newSettings.slidesPerView = 2;
          newSettings.spaceBetween = 15;
        }else if(screenWidth <= 1140){
          newSettings.slidesPerView = 3;
          newSettings.spaceBetween = 15;
        }else if(screenWidth <= 1350 ){
          newSettings.slidesPerView = 4;
          newSettings.spaceBetween = 15;
        }
        else{
          newSettings.slidesPerView = 5;
          newSettings.spaceBetween = 15;
        } 
        return newSettings;
      });
    };

    window.addEventListener("resize", updateSwiperSettings);
    updateSwiperSettings(); // Initial call
    // setPartners((prev)=>prev.map((elem)=>({...elem,isHovered:true})));
    return () => window.removeEventListener("resize", updateSwiperSettings);
  }, []); // Remove responsiveSettings from the dependency array
  return (
    <>
      <div className="col-sm-12 sports TeamSection">
        <div className="TeamHeadingContainer">
          <div className="blueSubHeading">Meet our Partners</div>
          <div className="whiteHeading">Partners</div>
        </div>
      </div>
      <div className="partnersSection">
        <img src={background} alt=""/>
        <div className="partnersRow">
          <div className="col-sm-12">
            <Swiper
              ref={partnersSwiperRef}
              modules={[Navigation, Pagination, A11y, Autoplay]} // Add Autoplay module
              slidesPerView={responsiveSettings.slidesPerView}
              spaceBetween={responsiveSettings.spaceBetween}
              centeredSlides={false} // Change to false for aligning to left
              autoplay={{
                delay: 1500, // Delay in ms (adjust as needed)
                disableOnInteraction: false, // Continue autoplay on user interaction
              }}
            >
              {partners.map((partner) => (
                <SwiperSlide key={partner.imageColor}>
                  <div className="partnersLogoContainer" >
                    <a
                      href={partner.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`partnersLogo ${partner.isHovered ? "isHovered" : ""}`}
                    >
                      <img
                        src={require("../assets/partners/" +
                          (
                            // partner.isHovered ?
                            partner.imageColor
                            // : partner.imageGray
                            ))}
                        alt="Partner"
                      />
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
